import {
    GetOrdersPublicId400,
    ServiceGetOrder,
    ServiceOrderStage,
} from '@dostavkee/contracts-console/entities'
import { GetOrdersPublicIdQueryKey, useGetOrdersPublicId } from '@dostavkee/contracts-console/hooks'
import { QueryObserverOptions } from '@tanstack/react-query'

interface UseOrderFetchProps {
    params: {
        public_id: string
    }
    query?: Partial<
        QueryObserverOptions<
            ServiceGetOrder,
            GetOrdersPublicId400,
            ServiceGetOrder,
            ServiceGetOrder,
            GetOrdersPublicIdQueryKey
        >
    >
}

export const useOrderFetch = ({ params, query }: UseOrderFetchProps) => {
    const { data, isLoading, refetch } = useGetOrdersPublicId(params.public_id, {
        query: {
            staleTime({ state }) {
                const stage = state.data?.order.stage
                if (
                    stage &&
                    [
                        ServiceOrderStage.OrderStageClientCanceled,
                        ServiceOrderStage.OrderStageOrderCompleted,
                    ].includes(stage)
                ) {
                    return 1000 * 60 * 30 // 30 minutes
                }

                return 0
            },
            ...query,
        },
    })

    return { order: data?.order, isLoading, refetch }
}
