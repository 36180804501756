import type { UseMutationOptions } from '@tanstack/react-query'
import { useMutation } from '@tanstack/react-query'

import client from '../../axios-client'
import type {
    PatchOrdersOrderIdArchive400,
    PatchOrdersOrderIdArchiveMutationResponse,
    PatchOrdersOrderIdArchivePathParams,
} from '../entities/PatchOrdersOrderIdArchive'

type PatchOrdersOrderIdArchiveClient = typeof client<
    PatchOrdersOrderIdArchiveMutationResponse,
    PatchOrdersOrderIdArchive400,
    never
>
type PatchOrdersOrderIdArchive = {
    data: PatchOrdersOrderIdArchiveMutationResponse
    error: PatchOrdersOrderIdArchive400
    request: never
    pathParams: PatchOrdersOrderIdArchivePathParams
    queryParams: never
    headerParams: never
    response: PatchOrdersOrderIdArchiveMutationResponse
    client: {
        parameters: Partial<Parameters<PatchOrdersOrderIdArchiveClient>[0]>
        return: Awaited<ReturnType<PatchOrdersOrderIdArchiveClient>>
    }
}
/**
 * @description Архивирование заказа
 * @summary Архивирование
 * @link /orders/:order_id/archive
 */
export function usePatchOrdersOrderIdArchive(
    options: {
        mutation?: UseMutationOptions<
            PatchOrdersOrderIdArchive['response'],
            PatchOrdersOrderIdArchive['error'],
            {
                orderId: PatchOrdersOrderIdArchivePathParams['order_id']
            }
        >
        client?: PatchOrdersOrderIdArchive['client']['parameters']
    } = {}
) {
    const { mutation: mutationOptions, client: clientOptions = {} } = options ?? {}

    return useMutation({
        mutationFn: async ({ orderId }) => {
            const res = await client<
                PatchOrdersOrderIdArchive['data'],
                PatchOrdersOrderIdArchive['error'],
                PatchOrdersOrderIdArchive['request']
            >({
                method: 'patch',
                url: `/orders/${orderId}/archive`,
                ...clientOptions,
            })

            return res.data
        },
        ...mutationOptions,
    })
}
