import type { UseMutationOptions } from '@tanstack/react-query'
import { useMutation } from '@tanstack/react-query'

import client from '../../axios-client'
import type {
    PostOrdersOrderIdGive400,
    PostOrdersOrderIdGiveMutationResponse,
    PostOrdersOrderIdGivePathParams,
} from '../entities/PostOrdersOrderIdGive'

type PostOrdersOrderIdGiveClient = typeof client<
    PostOrdersOrderIdGiveMutationResponse,
    PostOrdersOrderIdGive400,
    never
>
type PostOrdersOrderIdGive = {
    data: PostOrdersOrderIdGiveMutationResponse
    error: PostOrdersOrderIdGive400
    request: never
    pathParams: PostOrdersOrderIdGivePathParams
    queryParams: never
    headerParams: never
    response: PostOrdersOrderIdGiveMutationResponse
    client: {
        parameters: Partial<Parameters<PostOrdersOrderIdGiveClient>[0]>
        return: Awaited<ReturnType<PostOrdersOrderIdGiveClient>>
    }
}
/**
 * @description Ручка "Передал посылку курьеру"
 * @summary Ручка "Передал посылку курьеру"
 * @link /orders/:order_id/give
 */
export function usePostOrdersOrderIdGive(
    options: {
        mutation?: UseMutationOptions<
            PostOrdersOrderIdGive['response'],
            PostOrdersOrderIdGive['error'],
            {
                orderId: PostOrdersOrderIdGivePathParams['order_id']
            }
        >
        client?: PostOrdersOrderIdGive['client']['parameters']
    } = {}
) {
    const { mutation: mutationOptions, client: clientOptions = {} } = options ?? {}

    return useMutation({
        mutationFn: async ({ orderId }) => {
            const res = await client<
                PostOrdersOrderIdGive['data'],
                PostOrdersOrderIdGive['error'],
                PostOrdersOrderIdGive['request']
            >({
                method: 'post',
                url: `/orders/${orderId}/give`,
                ...clientOptions,
            })

            return res.data
        },
        ...mutationOptions,
    })
}
