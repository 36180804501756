import React, { FC, memo } from 'react'
import { Button, IconAdd } from '@dostavkee/web-ui'
import { Link, useNavigate } from '@tanstack/react-router'

import styles from './sidebar.module.scss'
import { SidebarBalance } from './sidebar-balance'
import { SidebarToggleSound } from './sidebar-toggle-sound'

export interface SidebarProps {
    disabled?: boolean
}

export const Sidebar: FC<SidebarProps> = memo(({ disabled }: SidebarProps) => {
    const navigate = useNavigate()

    return (
        <aside className={styles['sidebar']}>
            <div className={styles['sidebar__content']}>
                <div className={styles['create-order-button']}>
                    <Button
                        fullWidth
                        disabled={disabled}
                        prefixEl={<IconAdd color='white' />}
                        onClick={() => navigate({ to: '/orders/create' })}
                    >
                        Создать заказ
                    </Button>
                </div>
                <nav>
                    <ul>
                        <li>
                            <Link
                                activeProps={{ className: styles.active }}
                                className={styles['nav-link']}
                                disabled={disabled}
                                preload='intent'
                                to='/orders'
                            >
                                <span>Заказы</span>
                            </Link>
                        </li>
                        <li>
                            <Link
                                activeProps={{ className: styles.active }}
                                className={styles['nav-link']}
                                disabled={disabled}
                                id='addresses-link'
                                preload='intent'
                                to='/addresses'
                            >
                                <span>Адреса</span>
                            </Link>
                        </li>
                        <li>
                            <Link
                                activeProps={{ className: styles.active }}
                                className={styles['nav-link']}
                                disabled={disabled}
                                preload='intent'
                                to='/documents'
                            >
                                <span>Документы</span>
                            </Link>
                        </li>
                        <li>
                            <Link
                                activeProps={{ className: styles.active }}
                                className={styles['nav-link']}
                                disabled={disabled}
                                preload='intent'
                                to='/profile'
                            >
                                <span>Профиль</span>
                            </Link>
                        </li>
                        <li>
                            <Link
                                activeProps={{ className: styles.active }}
                                className={styles['nav-link']}
                                disabled={disabled}
                                preload='intent'
                                to='/supports'
                            >
                                <span>Помощь</span>
                            </Link>
                        </li>
                    </ul>
                </nav>

                <SidebarBalance disabled={disabled} />
            </div>
            <SidebarToggleSound />
        </aside>
    )
})

Sidebar.displayName = 'Sidebar'
