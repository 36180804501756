import React, { FC, memo, useEffect } from 'react'
import { moneyFormatter } from '@dostavkee/helpers'
import { Alert, Button, IconAdd, IconWarningFilled, Spinner } from '@dostavkee/web-ui'
import { useTour } from '@reactour/tour'
import { useRouterState } from '@tanstack/react-router'

import { useBalanceFetch } from '@/routes/_dashboard/-hooks/use-balance-fetch'
import { usePayment, useSidebar } from '@/shared/hooks'

import styles from './sidebar-balance.module.scss'

interface SidebarBalanceProps {
    disabled?: boolean
}

export const SidebarBalance: FC<SidebarBalanceProps> = memo(({ disabled }: SidebarBalanceProps) => {
    const { handleOpenPaymentModal } = usePayment()

    const { balance } = useBalanceFetch({
        query: {
            retryOnMount: false,
        },
    })

    const hash = useRouterState({ select: (state) => state.location.hash })
    const { setIsSidebarOpen } = useSidebar()
    const { setIsOpen: setIsOpenTour } = useTour()

    useEffect(() => {
        if (hash === 'tour' && typeof balance?.amount === 'number') {
            setIsSidebarOpen(true)
            setIsOpenTour(true)
        }
    }, [balance?.amount, hash, setIsOpenTour, setIsSidebarOpen])

    return (
        <div className={styles['sidebar-balance-wrapper']}>
            <section className={styles['sidebar-balance']} id='price-card'>
                <h1>Баланс</h1>
                <div className={styles['sidebar-balance-controls']}>
                    <div>
                        {typeof balance?.amount === 'number' ? (
                            moneyFormatter(balance.amount)
                        ) : (
                            <Spinner />
                        )}
                    </div>
                    <Button
                        isText
                        disabled={disabled}
                        prefixEl={<IconAdd color='white' size={16} />}
                        size='xs'
                        onClick={() => {
                            handleOpenPaymentModal()
                            setIsSidebarOpen(false)
                        }}
                    />
                </div>
            </section>
            {!disabled && typeof balance?.amount === 'number' && balance?.amount < 100 && (
                <Alert
                    icon={<IconWarningFilled color='var(--extensions-background-error)' />}
                    size='small'
                    style={{ marginTop: 8 }}
                    variant='error'
                >
                    <span className={styles['sidebar-balance-empty-alert-text']}>
                        Пополните баланс, чтобы сделать заказ
                    </span>
                </Alert>
            )}
        </div>
    )
})

SidebarBalance.displayName = 'SidebarBalance'
