import {
    ChangeEvent,
    FocusEvent,
    ForwardedRef,
    useCallback,
    useEffect,
    useRef,
    useState,
} from 'react'

import { useDeviceDetection } from './use-device-detection'

interface UseInputProps<TRef> {
    onChange?: (event: ChangeEvent<TRef>) => void
    onFocus?: (event: FocusEvent<TRef>) => void
    onBlur?: (event: FocusEvent<TRef>) => void
    ref?: ForwardedRef<TRef>
}
export const useInput = <TRef = HTMLInputElement>({
    ref,
    onBlur,
    onFocus,
}: UseInputProps<TRef>) => {
    const [isFocused, setIsFocused] = useState(false)
    const inputRef = useRef<TRef>(null)
    const { isMobile } = useDeviceDetection()

    useEffect(() => {
        if (!ref) {
            return
        }

        if (typeof ref === 'function') {
            ref(inputRef.current)
        } else {
            ref.current = inputRef.current
        }
    }, [ref])

    const handleFocus = useCallback(
        (event: FocusEvent<TRef>) => {
            if (typeof onFocus === 'function') {
                onFocus(event)
            }
            setIsFocused(true)

            /**
             * Fix for iOS devices: After a blur layout, element positions are incorrectly calculated.
             * This solution addresses the incorrect behavior. Further research into alternative solutions is needed.
             */
            if (isMobile) {
                window.scroll({ top: scrollY + 1 })
            }
        },
        [isMobile, onFocus]
    )

    const handleBlur = useCallback(
        (event: FocusEvent<TRef>) => {
            if (typeof onBlur === 'function') {
                onBlur(event)
            }

            /**
             * Fix for iOS devices: After a blur layout, element positions are incorrectly calculated.
             * This solution addresses the incorrect behavior. Further research into alternative solutions is needed.
             */
            if (isMobile) {
                window.scroll({ top: scrollY + 1 })
            }

            setIsFocused(false)
        },
        [isMobile, onBlur]
    )

    return {
        isFocused,
        handleFocus,
        handleBlur,
        inputRef,
        setIsFocused,
    }
}
