import React, { FC, memo } from 'react'
import { Burger, Button, IconChevronLeft } from '@dostavkee/web-ui'
import { useRouterState } from '@tanstack/react-router'
import clsx from 'clsx'

import { useNavigateBack, useSidebar } from '@/shared/hooks'

import styles from './header-mobile.module.scss'

interface HeaderMobileProps {
    params: Record<string, string>
}

const titles = {
    '/orders/active': 'Заказы',
    '/orders/archive': 'Заказы',
    '/orders/create': 'Создать заказ',
    '/orders/create/address': 'Создать заказ',
    '/addresses': 'Адреса',
    '/profile': 'Профиль',
    '/profile/transactions': 'История транзакций',
    '/supports': 'Помощь',
    '/documents': 'Документы',
}

export const HeaderMobile: FC<HeaderMobileProps> = memo(({ params }: HeaderMobileProps) => {
    const pathname = useRouterState({
        select: (state) => state.location.pathname,
    })

    const { isSidebarOpen, setIsSidebarOpen } = useSidebar()

    const isOrdersPage = ['/orders/active', '/orders/archive'].includes(pathname)
    const isCreateOrderPage = pathname.startsWith('/orders/create') && !isOrdersPage
    const isOrderPage = pathname.startsWith('/orders') && !isOrdersPage && !isCreateOrderPage

    const navigateBack = useNavigateBack({ fallback: '/orders/active' })

    const isLoadingRoute = useRouterState({
        select: (state) => state.isLoading,
    })

    return (
        <header
            className={clsx(styles['header-mobile'], {
                [styles['header-mobile--no-border']]: isLoadingRoute || isOrdersPage,
            })}
        >
            <div style={{ width: 32 }}>
                {isOrderPage || isCreateOrderPage ? (
                    <Button
                        isText
                        className={styles['order-header-mobile__back-btn']}
                        variant='ghost'
                        onClick={(event) => {
                            event.preventDefault()
                            event.stopPropagation()
                            navigateBack()
                        }}
                    >
                        <IconChevronLeft />
                    </Button>
                ) : (
                    <Burger isOpen={isSidebarOpen} onClick={() => setIsSidebarOpen(true)} />
                )}
            </div>

            <h1 className={styles['header-mobile__title']}>
                {titles[pathname as keyof typeof titles] ?? ''}
                {isOrderPage && params?.public_id && `Заказ №${params.public_id}`}
            </h1>
            <div style={{ width: 32 }} />
        </header>
    )
})

HeaderMobile.displayName = 'HeaderMobile'
