import React, { FC, SVGAttributes, useId } from 'react'
import clsx from 'clsx'

import styles from './spinner.module.scss'

export interface SpinnerProps extends SVGAttributes<SVGElement> {
    size?: 'small' | 'medium'
    color?: 'default' | 'white'
}

const sizes = { small: 16, medium: 24 }

export interface IHashOptions {
    value?: string
    prefix?: string
    length?: number
}

export const Spinner: FC<SpinnerProps> = ({ size = 'medium', color = 'default' }) => {
    const firstHalfID = useId()
    const secondHalfID = useId()

    return (
        <svg
            aria-hidden='true'
            fill='none'
            height={sizes[size]}
            role='alert'
            viewBox='-2 -2 23 23'
            width={sizes[size]}
            xmlns='http://www.w3.org/2000/svg'
            className={clsx(styles.spinner, {
                [styles['spinner--white']]: color === 'white',
            })}
        >
            <defs>
                <linearGradient id={firstHalfID}>
                    <stop offset='0%' stopColor='currentColor' stopOpacity='0' />
                    <stop offset='100%' stopColor='currentColor' stopOpacity='0.5' />
                </linearGradient>
                <linearGradient gradientTransform='translate(-0.1, 0)' id={secondHalfID}>
                    <stop offset='0%' stopColor='currentColor' stopOpacity='1' />
                    <stop offset='100%' stopColor='currentColor' stopOpacity='0.5' />
                </linearGradient>
            </defs>

            <g strokeWidth='6'>
                <path
                    d='M18 9.5C18 8.38376 17.7801 7.27846 17.353 6.24719C16.9258 5.21592 16.2997 4.27889 15.5104 3.48959C14.7211 2.70029 13.7841 2.07419 12.7528 1.64702C11.7215 1.21986 10.6162 1 9.5 1C8.38376 1 7.27846 1.21986 6.24719 1.64702C5.21592 2.07419 4.27889 2.70029 3.48959 3.48959C2.70029 4.27889 2.07419 5.21592 1.64702 6.24719C1.21986 7.27846 1 8.38376 1 9.5'
                    stroke={`url(#${firstHalfID})`}
                />
                <path
                    d='M1 9.5C1 10.6162 1.21986 11.7215 1.64702 12.7528C2.07419 13.7841 2.70029 14.7211 3.48959 15.5104C4.27889 16.2997 5.21592 16.9258 6.24719 17.353C7.27846 17.7801 8.38376 18 9.5 18C10.6162 18 11.7215 17.7801 12.7528 17.353C13.7841 16.9258 14.7211 16.2997 15.5104 15.5104C16.2997 14.7211 16.9258 13.7841 17.353 12.7528C17.7801 11.7215 18 10.6162 18 9.5'
                    stroke={`url(#${secondHalfID})`}
                />
                <path d='M1 10V10.3' stroke='currentColor' strokeLinecap='round' />
            </g>
        </svg>
    )
}

Spinner.displayName = 'Spinner'
