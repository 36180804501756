import type { QueryKey, QueryObserverOptions, UseQueryResult } from '@tanstack/react-query'
import { queryOptions, useQuery } from '@tanstack/react-query'

import client from '../../axios-client'
import type { GetUsersProfile400, GetUsersProfileQueryResponse } from '../entities/GetUsersProfile'

type GetUsersProfileClient = typeof client<GetUsersProfileQueryResponse, GetUsersProfile400, never>
type GetUsersProfile = {
    data: GetUsersProfileQueryResponse
    error: GetUsersProfile400
    request: never
    pathParams: never
    queryParams: never
    headerParams: never
    response: GetUsersProfileQueryResponse
    client: {
        parameters: Partial<Parameters<GetUsersProfileClient>[0]>
        return: Awaited<ReturnType<GetUsersProfileClient>>
    }
}
export const getUsersProfileQueryKey = () => [{ url: '/users/profile' }] as const
export type GetUsersProfileQueryKey = ReturnType<typeof getUsersProfileQueryKey>
export function getUsersProfileQueryOptions(options: GetUsersProfile['client']['parameters'] = {}) {
    const queryKey = getUsersProfileQueryKey()

    return queryOptions({
        queryKey,
        queryFn: async () => {
            const res = await client<GetUsersProfile['data'], GetUsersProfile['error']>({
                method: 'get',
                url: `/users/profile`,
                ...options,
            })

            return res.data
        },
    })
}
/**
 * @description Получение профиля
 * @summary Получение профиля
 * @link /users/profile
 */
export function useGetUsersProfile<
    TData = GetUsersProfile['response'],
    TQueryData = GetUsersProfile['response'],
    TQueryKey extends QueryKey = GetUsersProfileQueryKey,
>(
    options: {
        query?: Partial<
            QueryObserverOptions<
                GetUsersProfile['response'],
                GetUsersProfile['error'],
                TData,
                TQueryData,
                TQueryKey
            >
        >
        client?: GetUsersProfile['client']['parameters']
    } = {}
): UseQueryResult<TData, GetUsersProfile['error']> & {
    queryKey: TQueryKey
} {
    const { query: queryOptions, client: clientOptions = {} } = options ?? {}
    const queryKey = queryOptions?.queryKey ?? getUsersProfileQueryKey()
    const query = useQuery({
        ...(getUsersProfileQueryOptions(clientOptions) as unknown as QueryObserverOptions),
        queryKey,
        ...(queryOptions as unknown as Omit<QueryObserverOptions, 'queryKey'>),
    }) as UseQueryResult<TData, GetUsersProfile['error']> & {
        queryKey: TQueryKey
    }
    query.queryKey = queryKey as TQueryKey

    return query
}
