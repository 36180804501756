import { useCallback, useRef } from 'react'

type EventValue = Record<string, any>

interface EventPayload {
    event: string
    eventValue?: EventValue
}

interface Analytics {
    track: (event: string, eventValue?: EventValue) => void
}

export const track = (event: string, eventValue?: EventValue) => {
    const payload: EventPayload = {
        event,
    }

    if (eventValue) {
        payload.eventValue = eventValue
    }

    if (dataLayer && Array.isArray(dataLayer)) {
        dataLayer.push(payload)
    }
}

export const useAnalytics = () => {
    const trackMemo = useCallback(track, [])

    const analytics = useRef<Analytics>({ track: trackMemo })

    return analytics.current
}
