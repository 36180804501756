import type { ResponseConfig } from '../../axios-client'
import client from '../../axios-client'
import type { GetUsersLogoutQueryResponse } from '../entities/GetUsersLogout'

/**
 * @description User logout
 * @summary Logout
 * @link /users/logout
 */
export async function getUsersLogout(
    options: Partial<Parameters<typeof client>[0]> = {}
): Promise<ResponseConfig<GetUsersLogoutQueryResponse>['data']> {
    const res = await client<GetUsersLogoutQueryResponse>({
        method: 'get',
        url: `/users/logout`,
        ...options,
    })

    return res.data
}
