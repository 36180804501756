import { createFileRoute } from '@tanstack/react-router'

export const Route = createFileRoute('/_dashboard/orders/_tabs/archive')({
    onEnter: () => {
        document.title = 'Dostavkee - Архив'
    },
    validateSearch: (
        search: Record<string, unknown>
    ): {
        page?: number
    } => {
        return {
            page: (search.page as number) ?? 1,
        }
    },
})
