import { useState } from 'react'

import { useWindowSize } from './use-window-size'

export enum Device {
    Mobile = 'Mobile',
    Tablet = 'Tablet',
    Desktop = 'Desktop',
}

export const useDeviceDetection = () => {
    const [device] = useState<Device | undefined>()

    const { width } = useWindowSize()

    return {
        device,
        isMobile: width <= 1024,
        isTablet: width <= 1024,
        // isTablet: width > 1024 && width <= 1024,
        isDesktop: width > 1024,
    }
}
