import { useNavigate, useRouter } from '@tanstack/react-router'

interface UseNavigateBackProps {
    fallback: string
}

/**
 * Custom hook to navigate back in the browser history or fallback to a specified route
 */
export const useNavigateBack = ({ fallback }: UseNavigateBackProps) => {
    const { history } = useRouter()
    const navigate = useNavigate()

    /**
     * Return a function that can be called to navigate back or fallback
     */
    return () => {
        /**
         * Indicates whether the browser history has a key in its location state.
         */
        const hasBrowserHistory = history.location.state?.key

        if (hasBrowserHistory) {
            /**
             * If there is browser history, navigate back
             */
            return history.back()
        }

        /**
         * If there is no browser history, navigate to the fallback route
         */
        return navigate({ to: fallback })
    }
}
