import React, { FC } from 'react'

import { IconSvgProps } from './icon'

export const IconInfoFilled: FC<IconSvgProps> = ({
    size = 24,
    color = 'var(--text-and-icon-primary)',
}) => {
    return (
        <svg
            fill='none'
            height={size}
            viewBox='0 0 24 24'
            width={size}
            xmlns='http://www.w3.org/2000/svg'
        >
            <path
                clipRule='evenodd'
                d='M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12ZM12.268 9.71218C13.0297 9.71218 13.6472 9.09471 13.6472 8.33302C13.6472 7.57133 13.0297 6.95386 12.268 6.95386C11.5063 6.95386 10.8889 7.57133 10.8889 8.33302C10.8889 9.09471 11.5063 9.71218 12.268 9.71218ZM13.268 12.0461V16.0461C13.268 16.5986 12.818 17.0461 12.268 17.0461C11.718 17.0461 11.268 16.5986 11.268 16.0461V12.0461C11.268 11.4936 11.718 11.0461 12.268 11.0461C12.818 11.0461 13.268 11.4936 13.268 12.0461Z'
                fill={color}
                fillRule='evenodd'
            />
        </svg>
    )
}
