import React from 'react'
import clsx from 'clsx'

import styles from './table.module.scss'

export interface TableProps extends React.TableHTMLAttributes<HTMLTableElement> {
    isClickable?: boolean
    children: React.ReactNode
}

export const Table: React.FC<TableProps> = ({ children, isClickable = false, ...props }) => {
    return (
        <table
            className={clsx(styles['table'], {
                [styles['table--clickable']]: isClickable,
            })}
            {...props}
        >
            {children}
        </table>
    )
}
