import { GetOrdersQueryParamsType, ServiceOrder } from '@dostavkee/contracts-console/entities'
import {
    getOrdersPublicIdQueryKey,
    getOrdersQueryKey,
    getUsersProfileBalanceQueryKey,
    usePatchOrdersId,
} from '@dostavkee/contracts-console/hooks'
import * as Sentry from '@sentry/react'
import { useQueryClient } from '@tanstack/react-query'
import toast from 'react-hot-toast'

import { ERROR_CODES } from '@/shared/constants'

interface UseOrderUpdatePriceProps {
    order: ServiceOrder
}

export const useOrderUpdatePrice = ({ order }: UseOrderUpdatePriceProps) => {
    const queryClient = useQueryClient()

    const { mutate, isPending } = usePatchOrdersId({
        mutation: {
            onSuccess: async () => {
                /**
                 * Обновляем баланс пользователя
                 */
                await queryClient.invalidateQueries({
                    queryKey: getUsersProfileBalanceQueryKey(),
                })

                /**
                 * Обновляем кэш заказа
                 */
                await queryClient.invalidateQueries({
                    queryKey: getOrdersPublicIdQueryKey(order.public_id.toString()),
                })

                /**
                 * Обновляем кэш списка активных заказов
                 */
                await queryClient.invalidateQueries({
                    queryKey: getOrdersQueryKey({ type: GetOrdersQueryParamsType.active }),
                })

                toast.success('Цена заказа успешно поднята')
            },

            onError: (error) => {
                if (error.error_code === ERROR_CODES.ORDER_EDIT_COOLDOWN_ERROR_CODE) {
                    toast.error(
                        'Поднимать цену можно 1 раз в 15 секунд. Пожалуйста подождите немного и попробуйте снова.'
                    )

                    return
                }

                toast.error('Не удалось поднять цену заказа')

                Sentry.captureException(error)
            },
        },
    })

    return {
        mutate,
        isPending,
    }
}
