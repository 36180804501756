import type { UseMutationOptions } from '@tanstack/react-query'
import { useMutation } from '@tanstack/react-query'

import client from '../../axios-client'
import type {
    PostOrdersOrderIdCancel400,
    PostOrdersOrderIdCancelMutationRequest,
    PostOrdersOrderIdCancelMutationResponse,
    PostOrdersOrderIdCancelPathParams,
} from '../entities/PostOrdersOrderIdCancel'

type PostOrdersOrderIdCancelClient = typeof client<
    PostOrdersOrderIdCancelMutationResponse,
    PostOrdersOrderIdCancel400,
    PostOrdersOrderIdCancelMutationRequest
>
type PostOrdersOrderIdCancel = {
    data: PostOrdersOrderIdCancelMutationResponse
    error: PostOrdersOrderIdCancel400
    request: PostOrdersOrderIdCancelMutationRequest
    pathParams: PostOrdersOrderIdCancelPathParams
    queryParams: never
    headerParams: never
    response: PostOrdersOrderIdCancelMutationResponse
    client: {
        parameters: Partial<Parameters<PostOrdersOrderIdCancelClient>[0]>
        return: Awaited<ReturnType<PostOrdersOrderIdCancelClient>>
    }
}
/**
 * @description Отмена заказа
 * @summary Отмена заказа
 * @link /orders/:order_id/cancel
 */
export function usePostOrdersOrderIdCancel(
    options: {
        mutation?: UseMutationOptions<
            PostOrdersOrderIdCancel['response'],
            PostOrdersOrderIdCancel['error'],
            {
                orderId: PostOrdersOrderIdCancelPathParams['order_id']
                data: PostOrdersOrderIdCancel['request']
            }
        >
        client?: PostOrdersOrderIdCancel['client']['parameters']
    } = {}
) {
    const { mutation: mutationOptions, client: clientOptions = {} } = options ?? {}

    return useMutation({
        mutationFn: async ({ orderId, data }) => {
            const res = await client<
                PostOrdersOrderIdCancel['data'],
                PostOrdersOrderIdCancel['error'],
                PostOrdersOrderIdCancel['request']
            >({
                method: 'post',
                url: `/orders/${orderId}/cancel`,
                data,
                ...clientOptions,
            })

            return res.data
        },
        ...mutationOptions,
    })
}
