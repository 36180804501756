import React, { FC } from 'react'

import styles from './title.module.scss'

export interface TitleProps {
    children: React.ReactNode
}

export const Title: FC<TitleProps> = ({ children }) => {
    return <h1 className={styles['title']}>{children}</h1>
}

Title.displayName = 'Title'
