import React, { FC, useState } from 'react'
import { ServiceOrder } from '@dostavkee/contracts-console/entities'
import { useDeviceDetection } from '@dostavkee/react-hooks'
import { Button, Modal, TextArea } from '@dostavkee/web-ui'

import { useOrderCancelReasons } from '../../api/queries/use-order-cancel-reasons'
import { OrderCancelFormValues } from './order-cancel-modal'
import { OrderCancelModalLoader } from './order-cancel-modal.loader'
import styles from './order-cancel-modal-content-accepted.module.scss'

interface OrderCancelModalContentPickedProps {
    order: ServiceOrder
    onClose: () => void
    isPending: boolean
    onSubmit: (values: OrderCancelFormValues) => void
}

export const OrderCancelModalContentPicked: FC<OrderCancelModalContentPickedProps> = ({
    order,
    onClose,
    isPending,
    onSubmit,
}) => {
    const [values, setValues] = useState<OrderCancelFormValues>({
        reason: undefined,
        message: undefined,
    })
    const { reasons, isLoading } = useOrderCancelReasons({ order })

    const { isMobile } = useDeviceDetection()

    if (isLoading) {
        return <OrderCancelModalLoader />
    }

    return (
        <>
            <Modal.Header onClose={onClose}>Проблемы с заказом?</Modal.Header>
            <Modal.Body>
                <div className={styles['reasons-list']}>
                    {reasons?.map((reason) => (
                        <Button
                            key={reason.id}
                            isRounded
                            color={values.reason?.id === reason.id ? 'black' : 'primary'}
                            size={isMobile ? 'xs' : 'medium'}
                            variant='secondary'
                            onClick={() => setValues((state) => ({ ...state, reason }))}
                        >
                            {reason.reason}
                        </Button>
                    ))}
                </div>
                {values.reason?.message_required && (
                    <TextArea
                        id='reason-message'
                        label='Опишите ситуацию в подробностях'
                        value={values.message}
                        onChange={(event) =>
                            setValues((state) => ({ ...state, message: event.target.value }))
                        }
                    />
                )}
            </Modal.Body>
            <Modal.Footer>
                <Button isLoading={isPending} size='small' onClick={() => onSubmit(values)}>
                    Отправить
                </Button>
            </Modal.Footer>
        </>
    )
}
