import { createFileRoute } from '@tanstack/react-router'

export const Route = createFileRoute('/_auth/login/')({
    onEnter: () => {
        document.title = 'Dostavkee - Вход'
    },
    validateSearch: (
        search: Record<string, unknown>
    ): {
        email?: string
    } => {
        return {
            email: search?.email as string,
        }
    },
})
