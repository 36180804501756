import React, { FC } from 'react'

import { IconSvgProps } from './icon'

export const IconRepeat: FC<IconSvgProps> = ({
    size = 24,
    color = 'var(--text-and-icon-primary)',
}) => {
    return (
        <svg
            fill='none'
            height={size}
            viewBox='0 0 24 24'
            width={size}
            xmlns='http://www.w3.org/2000/svg'
        >
            <path
                d='M21 3.99762C21 3.44665 20.5533 3 20.0024 3C19.4514 3 19.0048 3.44665 19.0048 3.99762V6.478C17.3357 4.38922 14.7752 3.10296 11.9786 3.10296C7.01986 3.10296 3 7.10935 3 12.0515C3 16.9936 7.01986 21 11.9786 21C16.5996 21 20.4052 17.5209 20.9022 13.0475C20.9631 12.4999 20.5106 12.0515 19.9596 12.0515C19.4086 12.0515 18.9695 12.5007 18.8912 13.0461C18.407 16.4188 15.4966 19.0114 11.9786 19.0114C8.1218 19.0114 4.99525 15.8954 4.99525 12.0515C4.99525 8.2076 8.1218 5.09152 11.9786 5.09152C14.2655 5.09152 16.3492 6.19703 17.638 7.9714L15.0109 7.9714C14.4618 7.9714 14.0166 8.41655 14.0166 8.96568C14.0166 9.5148 14.4618 9.95996 15.0109 9.95996L20.0024 9.95996C20.5533 9.95996 21 9.5148 21 8.96568V3.99762Z'
                fill={color}
            />
        </svg>
    )
}
