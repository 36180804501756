import { ErrorStruct, GetUserBalanceResponse } from '@dostavkee/contracts-console/entities'
import {
    GetUsersProfileBalanceQueryKey,
    useGetUsersProfileBalance,
} from '@dostavkee/contracts-console/hooks'
import { QueryObserverOptions } from '@tanstack/react-query'

interface UseBalanceFetchProps {
    query?: Partial<
        QueryObserverOptions<
            GetUserBalanceResponse,
            ErrorStruct,
            GetUserBalanceResponse,
            GetUserBalanceResponse,
            GetUsersProfileBalanceQueryKey
        >
    >
}

export const useBalanceFetch = ({ query }: UseBalanceFetchProps = {}) => {
    const { data, isLoading } = useGetUsersProfileBalance({
        query,
    })

    return {
        balance: data,
        isLoading,
    }
}
