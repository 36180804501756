/**
 * Copies the given text to the clipboard.
 *
 * This function uses the clipboard API if available, and falls back to a
 * technique that creates a hidden textarea if the API is not available.
 *
 * @param {string} text - The text to be copied.
 * @return {Promise<void>} A promise that resolves once the text is copied to the
 * clipboard. If the clipboard API is not available, the promise is resolved
 * immediately.
 */
export const copyToClipboard = (text: string): Promise<void> => {
    if (navigator.clipboard) {
        /**
         * Use the Clipboard API if it's available.
         */
        return navigator.clipboard.writeText(text)
    }

    /**
     * If the Clipboard API is not available, fall back to creating a hidden
     * textarea.
     */
    const textarea = document.createElement('textarea')
    textarea.value = text
    textarea.setAttribute('readonly', '')
    textarea.style.position = 'absolute'
    textarea.style.left = '-9999px'

    document.body.append(textarea)
    textarea.select()
    document.execCommand('copy')
    textarea.remove()

    return Promise.resolve()
}
