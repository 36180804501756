import React, { FC, HTMLAttributes } from 'react'
import clsx from 'clsx'

import styles from './logo.module.scss'

export interface LogoProps extends HTMLAttributes<HTMLDivElement> {
    width?: number
    color?: string
    className?: string
}

export const Logo: FC<LogoProps> = ({
    width = 200,
    className = '',
    color = '#0050C8',
    ...props
}) => {
    return (
        <div className={clsx(styles.logo, className)} {...props}>
            <svg
                fill='none'
                height='52'
                viewBox='0 0 203 52'
                width={width}
                xmlns='http://www.w3.org/2000/svg'
            >
                <path
                    d='M10.0257 15.6351C12.9781 15.6351 15.6845 17.0436 16.5719 19.0119V8.58398H22.1955V36.2811H16.5719V33.6216C15.5175 35.4762 12.7672 36.7972 10.0257 36.7972C4.36704 36.7972 0 32.2044 0 26.2118C0 20.2192 4.36704 15.6264 10.0257 15.6264M5.62356 26.2205C5.62356 29.2824 8.09265 31.732 11.1592 31.732C14.2258 31.732 16.7389 29.2737 16.7389 26.2205C16.7389 23.1674 14.2346 20.6654 11.1592 20.6654C8.08386 20.6654 5.62356 23.1586 5.62356 26.2205Z'
                    fill='#323942'
                />
                <path
                    d='M167.538 1H187.528C195.893 1 202.685 7.76242 202.685 16.0908V35.9931C202.685 44.3215 195.893 51.0839 187.528 51.0839H167.538C159.173 51.0839 152.381 44.3215 152.381 35.9931V16.0908C152.381 7.76242 159.173 1 167.538 1Z'
                    fill={color}
                />
                <path
                    d='M202.685 35.9945V31.2092C199.812 33.8687 196.288 35.7933 192.853 36.3619C189.654 36.8868 187.194 36.4844 185.331 35.4784C189.136 33.6675 192.484 31.3317 194.83 28.7159C199.355 23.7207 199.68 17.0807 195.638 13.2577C191.956 9.71466 185.129 10.1608 180.762 14.3862C175.833 18.9791 174.295 26.8613 176.597 33.0201C171.378 34.2711 166.202 34.1049 163.338 32.2153C166.896 30.6056 169.805 28.6285 171.668 26.3801C174.541 22.9158 174.857 17.2469 171.791 14.3425C168.232 11.0007 161.888 12.0067 158.329 16.3984C155.825 19.4952 154.446 24.613 155.623 29.3983C154.569 29.6695 153.479 29.9057 152.398 30.1157V35.6008C154.34 35.2422 156.238 34.796 158.057 34.2361C162.143 38.9427 170.552 40.3162 179.523 37.7004C182.634 40.9985 187.607 42.8094 194.645 41.4884C197.369 40.9723 200.031 39.905 202.492 38.3828C202.615 37.6042 202.703 36.8169 202.703 36.0033L202.685 35.9945ZM162.652 19.4603C164.19 17.5706 166.694 17.0457 168.03 18.253C169.163 19.3378 168.882 21.4724 167.178 23.5282C165.807 25.1816 163.54 26.5901 160.711 27.7536C160.227 24.8929 160.834 21.6386 162.652 19.4603ZM184.154 18.7341C186.737 16.0397 190.094 15.6722 191.71 17.2032C193.652 19.0141 193.204 22.4784 190.419 25.5316C188.073 28.0686 185.085 29.9232 181.764 31.2879C180.103 27.185 181.236 21.7086 184.145 18.7254L184.154 18.7341Z'
                    fill='white'
                />
                <path
                    d='M35.7619 15.7852C42.3432 15.7852 46.6399 19.9843 46.6399 26.2918C46.6399 32.5994 42.3432 36.7985 35.7619 36.7985C29.1805 36.7985 24.8838 32.5994 24.8838 26.2918C24.8838 19.9843 29.1805 15.7852 35.7619 15.7852ZM40.9021 26.2918C40.9021 22.8188 38.9339 20.378 35.7619 20.378C32.5898 20.378 30.6655 22.8188 30.6655 26.2918C30.6655 29.7649 32.5898 32.2057 35.7619 32.2057C38.9339 32.2057 40.9021 29.7649 40.9021 26.2918Z'
                    fill='#323942'
                />
                <path
                    d='M58.265 32.7657C60.6287 32.7657 62.0785 32.0046 62.0785 30.5699C62.0785 28.8902 60.6726 28.7677 57.3424 28.3303C52.5272 27.7704 48.7929 26.6944 48.7929 22.4515C48.7929 18.2085 52.3691 15.6978 57.6675 15.7328C63.2032 15.7328 67.0167 18.1736 67.2979 22.364H61.8764C61.7182 20.7281 60.1542 19.722 57.8608 19.722C55.5675 19.722 54.2055 20.5181 54.2055 21.8391C54.2055 23.3963 55.9717 23.5538 58.9416 23.9124C63.6777 24.3936 67.6142 25.4696 67.6142 30.1062C67.6142 34.1829 63.7568 36.7812 58.2211 36.7812C52.6854 36.7812 48.5908 34.1042 48.3447 29.7038H53.7223C53.9243 31.5059 55.4884 32.7395 58.2562 32.7395L58.265 32.7657Z'
                    fill='#323942'
                />
                <path
                    d='M72.1217 20.7791H68.7124V16.02H72.1217V8.58398H77.7804V16.02H82.5165V20.7791H77.7804V29.7723C77.7804 31.2071 78.3427 31.8107 79.8277 31.8107H82.6395V36.5697H78.0264C74.0899 36.5697 72.1217 34.6101 72.1217 30.6909V20.7791Z'
                    fill='#323942'
                />
                <path
                    d='M94.6075 15.7852C100.389 15.7852 103.719 18.3397 103.719 23.2562V30.526C103.719 31.6021 104.159 31.8033 105.046 31.8033H105.688V36.5623H102.797C99.862 36.5623 98.9394 35.1626 98.9834 33.203C97.5423 35.4776 95.4511 36.7985 92.2439 36.7985C87.789 36.7985 84.573 34.6815 84.573 30.7622C84.573 26.4056 87.868 23.9298 94.0452 23.9298H98.1398V22.9325C98.1398 21.0954 96.813 19.8969 94.4494 19.8969C92.2439 19.8969 90.7589 20.8942 90.5129 22.4164H85.0563C85.4605 18.4184 89.1509 15.7852 94.6075 15.7852ZM93.3247 32.7306C96.2946 32.7306 98.1047 30.8934 98.1398 28.094V27.6916H94.0012C91.6288 27.6916 90.2669 28.6539 90.2669 30.3685C90.2669 31.8033 91.4267 32.7306 93.3159 32.7306H93.3247Z'
                    fill='#323942'
                />
                <path
                    d='M110.863 16.0195L115.837 30.8041L120.731 16.0195H126.671L119.246 36.5605H112.216L104.792 16.0195H110.854H110.863Z'
                    fill='#323942'
                />
                <path
                    d='M134.394 8.58398V24.7333L142.46 16.02H148.84L141.415 24.0947L149.244 36.561H142.698L137.478 28.2501L134.385 31.5657V36.561H128.727V8.58398H134.385H134.394Z'
                    fill='#323942'
                />
            </svg>
        </div>
    )
}

Logo.displayName = 'Logo'
