import { createFileRoute } from '@tanstack/react-router'

export const Route = createFileRoute('/_auth/invite/')({
    validateSearch: (
        search: Record<string, unknown>
    ): {
        code: string
        code_uuid: string
        email: string
    } => {
        return {
            code: search?.code?.toString() as string,
            code_uuid: search.code_uuid as string,
            email: search.email as string,
        }
    },
    onEnter: () => {
        document.title = 'Dostavkee - Приглашение'
    },
})
