import { useAtom } from 'jotai'
import { atomWithStorage } from 'jotai/utils'

const authAtom = atomWithStorage<boolean>('@dostavkee/mute', false)

export const useMute = () => {
    const [isMuted, setIsMuted] = useAtom(authAtom)

    return { isMuted, setIsMuted }
}
