import { ErrorStruct } from './ErrorStruct'
import type { ServiceGetOrders } from './service/GetOrders'

export enum GetOrdersQueryParamsType {
    'active' = 'active',
    'archive' = 'archive',
}
export type GetOrdersQueryParams = {
    /**
     * @description type enum
     * @type string
     */
    type: GetOrdersQueryParamsType
    /**
     * @description int limit
     * @type integer | undefined
     */
    limit?: number
    /**
     * @description int offset
     * @type integer | undefined
     */
    offset?: number
}
/**
 * @description OK
 */
export type GetOrders200 = ServiceGetOrders
/**
 * @description Bad Request
 */
export type GetOrders400 = ErrorStruct
/**
 * @description OK
 */
export type GetOrdersQueryResponse = ServiceGetOrders
export type GetOrdersQuery = {
    Response: GetOrdersQueryResponse
    QueryParams: GetOrdersQueryParams
    Errors: GetOrders400
}
