import React, { FC } from 'react'
import clsx from 'clsx'

import styles from './input-message.module.scss'

export interface InputMessageProps {
    children: React.ReactNode
    variant?: 'error' | 'warning' | 'info' | 'success' | 'default'
}

export const InputMessage: FC<InputMessageProps> = ({ children, variant }) => {
    return (
        <div
            className={clsx(styles['input-message'], {
                [styles['input-message--error']]: variant === 'error',
                [styles['input-message--warning']]: variant === 'warning',
                [styles['input-message--info']]: variant === 'info',
            })}
        >
            {children}
        </div>
    )
}

InputMessage.displayName = 'InputMessage'
