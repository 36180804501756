import { useCallback } from 'react'
import {
    usePostUsersForgotPassword,
    usePostUsersForgotPasswordVerify,
} from '@dostavkee/contracts-console/hooks'
import { getCountdownDiffSeconds } from '@dostavkee/helpers'
import * as Sentry from '@sentry/react'
import { useNavigate } from '@tanstack/react-router'
import { useAtom } from 'jotai'
import { atomWithStorage, createJSONStorage } from 'jotai/utils'
import toast from 'react-hot-toast'

import { EmailConfirmationFormValues } from '@/routes/_auth/login/reset-password/-ui'
import { ResetPasswordFormValues } from '@/routes/_auth/login/reset-password/confirm/-ui'

import { ERROR_CODES, TIMEOUT_RESEND_EMAIL_CODE_SECONDS } from '../constants'
import { useAuth } from './use-auth'

export interface IPasswordResetData {
    code_uuid?: string
    email?: string
    timestampEmailRequested?: number
}

const persistStorage = createJSONStorage<IPasswordResetData | undefined>(() => localStorage)
const passwordResetDataAtom = atomWithStorage<IPasswordResetData | undefined>(
    '@dostavkee/reset-password',
    undefined,
    persistStorage
)

export const usePasswordReset = () => {
    const [passwordResetData, setPasswordResetData] = useAtom(passwordResetDataAtom)
    const { setAuth } = useAuth()
    const navigate = useNavigate()
    const email = passwordResetData?.email
    const timestampEmailRequested = passwordResetData?.timestampEmailRequested

    const { mutateAsync: forgotPassword } = usePostUsersForgotPassword()

    const handleSendVerifyCode = useCallback(
        async (data: EmailConfirmationFormValues) => {
            const reminder = getCountdownDiffSeconds(
                passwordResetData?.timestampEmailRequested,
                TIMEOUT_RESEND_EMAIL_CODE_SECONDS
            )

            if (reminder > 0) {
                toast.error(`Повторная отправка кода возможна через ${reminder} секунд`)

                return
            }

            const toastId = toast.loading('Отправка кода...')

            return forgotPassword(
                {
                    data,
                },
                {
                    onSuccess: ({ code_id }) => {
                        toast.success(`Мы отправили код вам на почту: ${data.email}`, {
                            id: toastId,
                        })

                        setPasswordResetData(() => ({
                            email: data.email,
                            code_uuid: code_id,
                            timestampEmailRequested: Math.floor(Date.now() / 1000),
                        }))

                        navigate({
                            to: '/login/reset-password/confirm',
                            mask: { to: '/login/reset-password' },
                        })
                    },
                    onError: (error) => {
                        if (error.error_code === ERROR_CODES.USER_NOT_FOUND) {
                            toast.error('Пользователь с указанной почтой не найден', {
                                id: toastId,
                            })

                            return
                        }

                        if (error.error_message) {
                            toast.error(error.error_message, {
                                id: toastId,
                            })

                            return
                        }

                        toast.error('Неизвестная ошибка', {
                            id: toastId,
                        })

                        Sentry.captureException(error)
                    },
                }
            )
        },
        [forgotPassword, navigate, passwordResetData?.timestampEmailRequested, setPasswordResetData]
    )

    const { mutateAsync: forgotPasswordVerify } = usePostUsersForgotPasswordVerify()

    const handlePasswordReset = useCallback(
        async (data: ResetPasswordFormValues) => {
            const toastId = toast.loading('Отправка кода...')

            return forgotPasswordVerify(
                {
                    data: {
                        code_uuid: passwordResetData?.code_uuid!,
                        email: passwordResetData?.email!,
                        code: data.verify_code,
                        password: data.password,
                    },
                },
                {
                    onSuccess: ({ access_token }) => {
                        toast.success(`Пароль изменён`, { id: toastId })

                        setPasswordResetData(undefined)

                        setAuth({ access_token })

                        navigate({ to: '/orders/active' })
                    },
                    onError: (error) => {
                        if (error.error_code === ERROR_CODES.USER_VERIFICATION_CODE_DOESNT_EXISTS) {
                            toast.error('Указан неверный код верификации', {
                                id: toastId,
                            })

                            return
                        }

                        if (error.error_message) {
                            toast.error(error.error_message, {
                                id: toastId,
                            })

                            return
                        }

                        toast.error('Неизвестная ошибка', {
                            id: toastId,
                        })

                        Sentry.captureException(error)
                    },
                }
            )
        },
        [
            forgotPasswordVerify,
            navigate,
            passwordResetData?.code_uuid,
            passwordResetData?.email,
            setAuth,
            setPasswordResetData,
        ]
    )

    return { handleSendVerifyCode, handlePasswordReset, email, timestampEmailRequested }
}
