import type { QueryKey, QueryObserverOptions, UseQueryResult } from '@tanstack/react-query'
import { queryOptions, useQuery } from '@tanstack/react-query'

import client from '../../axios-client'
import type {
    GetOrdersPublicId400,
    GetOrdersPublicIdPathParams,
    GetOrdersPublicIdQueryResponse,
} from '../entities/GetOrdersPublicId'

type GetOrdersPublicIdClient = typeof client<
    GetOrdersPublicIdQueryResponse,
    GetOrdersPublicId400,
    never
>
type GetOrdersPublicId = {
    data: GetOrdersPublicIdQueryResponse
    error: GetOrdersPublicId400
    request: never
    pathParams: GetOrdersPublicIdPathParams
    queryParams: never
    headerParams: never
    response: GetOrdersPublicIdQueryResponse
    client: {
        parameters: Partial<Parameters<GetOrdersPublicIdClient>[0]>
        return: Awaited<ReturnType<GetOrdersPublicIdClient>>
    }
}
export const getOrdersPublicIdQueryKey = (publicId: GetOrdersPublicIdPathParams['public_id']) =>
    [{ url: '/orders/:public_id', params: { publicId: publicId } }] as const
export type GetOrdersPublicIdQueryKey = ReturnType<typeof getOrdersPublicIdQueryKey>
export function getOrdersPublicIdQueryOptions(
    publicId: GetOrdersPublicIdPathParams['public_id'],
    options: GetOrdersPublicId['client']['parameters'] = {}
) {
    const queryKey = getOrdersPublicIdQueryKey(publicId)

    return queryOptions({
        queryKey,
        queryFn: async () => {
            const res = await client<GetOrdersPublicId['data'], GetOrdersPublicId['error']>({
                method: 'get',
                url: `/orders/${publicId}`,
                ...options,
            })

            return res.data
        },
    })
}
/**
 * @description Получение заказа
 * @summary Получение заказа
 * @link /orders/:public_id
 */
export function useGetOrdersPublicId<
    TData = GetOrdersPublicId['response'],
    TQueryData = GetOrdersPublicId['response'],
    TQueryKey extends QueryKey = GetOrdersPublicIdQueryKey,
>(
    publicId: GetOrdersPublicIdPathParams['public_id'],
    options: {
        query?: Partial<
            QueryObserverOptions<
                GetOrdersPublicId['response'],
                GetOrdersPublicId['error'],
                TData,
                TQueryData,
                TQueryKey
            >
        >
        client?: GetOrdersPublicId['client']['parameters']
    } = {}
): UseQueryResult<TData, GetOrdersPublicId['error']> & {
    queryKey: TQueryKey
} {
    const { query: queryOptions, client: clientOptions = {} } = options ?? {}
    const queryKey = queryOptions?.queryKey ?? getOrdersPublicIdQueryKey(publicId)
    const query = useQuery({
        ...(getOrdersPublicIdQueryOptions(
            publicId,
            clientOptions
        ) as unknown as QueryObserverOptions),
        queryKey,
        ...(queryOptions as unknown as Omit<QueryObserverOptions, 'queryKey'>),
    }) as UseQueryResult<TData, GetOrdersPublicId['error']> & {
        queryKey: TQueryKey
    }
    query.queryKey = queryKey as TQueryKey

    return query
}
