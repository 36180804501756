import React, { FC } from 'react'

import styles from './app-version.module.scss'

export interface AppVersionProps {
    version: number | string
}

export const AppVersion: FC<AppVersionProps> = ({ version }) => {
    return <div className={styles['app-version']}>Версия: v.{version}</div>
}

AppVersion.displayName = 'AppVersion'
