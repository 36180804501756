import dayjs from 'dayjs'

export const getCreatedTime = (date: string) => {
    if (dayjs(date).isSame(dayjs(), 'day')) {
        return `Сегодня, ${dayjs(date).format('HH:mm')}`
    }

    if (dayjs(date).isSame(dayjs().subtract(1, 'day'), 'day')) {
        return `Вчера, ${dayjs(date).format('HH:mm')}`
    }

    return dayjs(date).format('DD.MM.YYYY, HH:mm')
}
