import React, { FC, lazy, Suspense } from 'react'

import { Spinner } from '../spinner'

const IconPhone = lazy(async () =>
    import('./icon-phone').then((module) => ({ default: module.IconPhone }))
)

const IconMoreVertical = lazy(async () =>
    import('./icon-more-vertical').then((module) => ({ default: module.IconMoreVertical }))
)

const IconEdit = lazy(async () =>
    import('./icon-edit').then((module) => ({ default: module.IconEdit }))
)

const IconDelete = lazy(async () =>
    import('./icon-delete').then((module) => ({ default: module.IconDelete }))
)

const IconAdd = lazy(async () =>
    import('./icon-add').then((module) => ({ default: module.IconAdd }))
)

const IconClose = lazy(async () =>
    import('./icon-close').then((module) => ({ default: module.IconClose }))
)

const IconEyeOn = lazy(async () =>
    import('./icon-eye-on').then((module) => ({ default: module.IconEyeOn }))
)

const IconEyeOff = lazy(async () =>
    import('./icon-eye-off').then((module) => ({ default: module.IconEyeOff }))
)

const IconCheck = lazy(async () =>
    import('./icon-check').then((module) => ({ default: module.IconCheck }))
)

const IconCircleCheck = lazy(async () =>
    import('./icon-circle-check').then((module) => ({ default: module.IconCircleCheck }))
)

const IconWrongCheck = lazy(async () =>
    import('./icon-wrong-check').then((module) => ({ default: module.IconWrongCheck }))
)

const IconArrowLeft = lazy(async () =>
    import('./icon-arrow-left').then((module) => ({ default: module.IconArrowLeft }))
)

const IconRepeat = lazy(async () =>
    import('./icon-repeat').then((module) => ({ default: module.IconRepeat }))
)

const IconAddress = lazy(async () =>
    import('./icon-address').then((module) => ({ default: module.IconAddress }))
)

const IconAddressA = lazy(async () =>
    import('./icon-address-a').then((module) => ({ default: module.IconAddressA }))
)

const IconAddressB = lazy(async () =>
    import('./icon-address-b').then((module) => ({ default: module.IconAddressB }))
)

const IconSearch = lazy(async () =>
    import('./icon-search').then((module) => ({ default: module.IconSearch }))
)

const IconClearFilled = lazy(async () =>
    import('./icon-clear-filled').then((module) => ({ default: module.IconClearFilled }))
)

const IconHistory = lazy(async () =>
    import('./icon-history').then((module) => ({ default: module.HistoryIcon }))
)

const IconForwardArrow = lazy(async () =>
    import('./icon-forward-arrow').then((module) => ({ default: module.IconForwardArrow }))
)

const IconMinus = lazy(async () =>
    import('./icon-minus').then((module) => ({ default: module.IconMinus }))
)

const IconTransfer = lazy(async () =>
    import('./icon-transfer').then((module) => ({ default: module.IconTransfer }))
)

const IconWalletCoin = lazy(async () =>
    import('./icon-wallet-icon').then((module) => ({ default: module.IconWalletCoin }))
)

const IconArrowDown = lazy(async () =>
    import('./icon-down-arrow').then((module) => ({ default: module.IconArrowDown }))
)

const IconTermopack = lazy(async () =>
    import('./icon-termopack').then((module) => ({ default: module.IconTermopack }))
)

const IconCar = lazy(async () =>
    import('./icon-car').then((module) => ({ default: module.IconCar }))
)

const IconChevronLeft = lazy(async () =>
    import('./icon-chevron-left').then((module) => ({ default: module.IconChevronLeft }))
)

const IconChevronUp = lazy(async () =>
    import('./icon-chevron-up').then((module) => ({ default: module.IconChevronUp }))
)

const IconWarningOutline = lazy(async () =>
    import('./icon-warning-outline').then((module) => ({ default: module.IconWarningOutline }))
)

const IconWarningFilled = lazy(async () =>
    import('./icon-warning-filled').then((module) => ({ default: module.IconWarningFilled }))
)

const IconExit = lazy(async () =>
    import('./icon-exit').then((module) => ({ default: module.IconExit }))
)

const IconInfoOutline = lazy(async () =>
    import('./icon-info-outline').then((module) => ({ default: module.IconInfoOutline }))
)

const IconInfoFilled = lazy(async () =>
    import('./icon-info-filled').then((module) => ({ default: module.IconInfoFilled }))
)

const IconEnterDoor = lazy(async () =>
    import('./icon-enter-door').then((module) => ({ default: module.IconEnterDoor }))
)

const IconChevronRightSmall = lazy(async () =>
    import('./icon-chevron-right-small').then((module) => ({
        default: module.IconChevronRightSmall,
    }))
)

const IconChevronLeftSmall = lazy(async () =>
    import('./icon-chevron-left-small').then((module) => ({ default: module.IconChevronLeftSmall }))
)

const IconFullscreen = lazy(async () =>
    import('./icon-fullscreen').then((module) => ({ default: module.IconFullscreen }))
)

const IconArrowRight = lazy(async () =>
    import('./icon-arrow-right').then((module) => ({ default: module.IconArrowRight }))
)

const IconCourier = lazy(async () =>
    import('./icon-courier').then((module) => ({ default: module.IconCourier }))
)

const IconChatFilled = lazy(async () =>
    import('./icon-chat-filled').then((module) => ({ default: module.IconChatFilled }))
)

const IconChatOutline = lazy(async () =>
    import('./icon-chat-outline').then((module) => ({ default: module.IconChatOutline }))
)

const IconAsk = lazy(async () =>
    import('./icon-ask').then((module) => ({ default: module.IconAsk }))
)

const IconVoipCallsSpeaker = lazy(async () =>
    import('./icon-voip-calls-speaker').then((module) => ({
        default: module.IconVoipCallsSpeaker,
    }))
)

const IconVoipCallsSpeakerMute = lazy(async () =>
    import('./icon-voip-calls-speaker-mute').then((module) => ({
        default: module.IconVoipCallsSpeakerMute,
    }))
)

const IconLink = lazy(async () =>
    import('./icon-link').then((module) => ({ default: module.IconLink }))
)

const IconWallet = lazy(async () =>
    import('./icon-wallet').then((module) => ({ default: module.IconWallet }))
)

const IconCoins = lazy(async () =>
    import('./icon-coins').then((module) => ({ default: module.IconCoins }))
)

const IconArrowUp = lazy(async () =>
    import('./icon-arrow-up').then((module) => ({ default: module.IconArrowUp }))
)

const IconBolt = lazy(async () =>
    import('./icon-bolt').then((module) => ({ default: module.IconBolt }))
)

const icons = {
    add: IconAdd,
    'address-a': IconAddressA,
    'address-b': IconAddressB,
    address: IconAddress,
    'arrow-down': IconArrowDown,
    'arrow-left': IconArrowLeft,
    'arrow-right': IconArrowRight,
    courier: IconCourier,
    check: IconCheck,
    close: IconClose,
    delete: IconDelete,
    edit: IconEdit,
    history: IconHistory,
    'wallet-coin': IconWalletCoin,
    transfer: IconTransfer,
    minus: IconMinus,
    'more-vertical': IconMoreVertical,
    phone: IconPhone,
    repeat: IconRepeat,
    search: IconSearch,
    'clear-filled': IconClearFilled,
    'forward-arrow': IconForwardArrow,
    'eye-off': IconEyeOff,
    'eye-on': IconEyeOn,
    'wrong-check': IconWrongCheck,
    'warning-outline': IconWarningOutline,
    'warning-filled': IconWarningFilled,
    exit: IconExit,
    'info-outline': IconInfoOutline,
    'info-filled': IconInfoFilled,
    termopack: IconTermopack,
    car: IconCar,
    'chevron-left': IconChevronLeft,
    'chevron-up': IconChevronUp,
    'enter-door': IconEnterDoor,
    'chevron-right-small': IconChevronRightSmall,
    'chevron-left-small': IconChevronLeftSmall,
    fullscreen: IconFullscreen,
    'chat-filled': IconChatFilled,
    'chat-outline': IconChatOutline,
    ask: IconAsk,
    'voip-calls-speaker': IconVoipCallsSpeaker,
    'voip-calls-speaker-mute': IconVoipCallsSpeakerMute,
    'circle-check': IconCircleCheck,
    link: IconLink,
    wallet: IconWallet,
    coins: IconCoins,
    'arrow-up': IconArrowUp,
    bolt: IconBolt,
}

export interface IconProps {
    icon: keyof typeof icons
    color?: string
    size?: number
}

export interface IconSvgProps extends Omit<IconProps, 'icon'> {}

export const Icon: FC<IconProps> = ({ icon, ...props }) => {
    const IconSvg = icons[icon]

    return (
        <Suspense fallback={<Spinner />}>
            <IconSvg {...props} />
        </Suspense>
    )
}

Icon.displayName = 'Icon'
