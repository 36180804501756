import type { UseMutationOptions } from '@tanstack/react-query'
import { useMutation } from '@tanstack/react-query'

import client from '../../axios-client'
import type {
    PostUsersProfileInvoice400,
    PostUsersProfileInvoiceMutationRequest,
    PostUsersProfileInvoiceMutationResponse,
} from '../entities/PostUsersProfileInvoice'

type PostUsersProfileInvoiceClient = typeof client<
    PostUsersProfileInvoiceMutationResponse,
    PostUsersProfileInvoice400,
    PostUsersProfileInvoiceMutationRequest
>
type PostUsersProfileInvoice = {
    data: PostUsersProfileInvoiceMutationResponse
    error: PostUsersProfileInvoice400
    request: PostUsersProfileInvoiceMutationRequest
    pathParams: never
    queryParams: never
    headerParams: never
    response: PostUsersProfileInvoiceMutationResponse
    client: {
        parameters: Partial<Parameters<PostUsersProfileInvoiceClient>[0]>
        return: Awaited<ReturnType<PostUsersProfileInvoiceClient>>
    }
}
/**
 * @description Генерация счета
 * @summary Генерация счета
 * @link /users/profile/invoice
 */
export function usePostUsersProfileInvoice(
    options: {
        mutation?: UseMutationOptions<
            PostUsersProfileInvoice['response'],
            PostUsersProfileInvoice['error'],
            {
                data?: PostUsersProfileInvoice['request']
            }
        >
        client?: PostUsersProfileInvoice['client']['parameters']
    } = {}
) {
    const { mutation: mutationOptions, client: clientOptions = {} } = options ?? {}

    return useMutation({
        mutationFn: async ({ data }) => {
            const res = await client<
                PostUsersProfileInvoice['data'],
                PostUsersProfileInvoice['error'],
                PostUsersProfileInvoice['request']
            >({
                method: 'post',
                url: `/users/profile/invoice`,
                data,
                ...clientOptions,
            })

            return res.data
        },
        ...mutationOptions,
    })
}
