import React, { FC, useState } from 'react'
import { Button, Modal } from '@dostavkee/web-ui'

import { useAuth } from '@/shared/hooks'

import styles from './logout-modal.module.scss'

export interface LogoutModalProps {
    isOpen: boolean
    onClose: () => void
}

export const LogoutModal: FC<LogoutModalProps> = (props) => {
    const { onLogout } = useAuth()

    const [isLoggingOut, setIsLoggingOut] = useState(false)

    const handleLogout = async () => {
        setIsLoggingOut(true)

        try {
            await onLogout()
        } finally {
            setIsLoggingOut(false)
        }
    }

    return (
        <Modal {...props}>
            <Modal.Header onClose={props.onClose}>Выход из аккаунта</Modal.Header>
            <Modal.Body>
                <div className={styles['logout-modal']}>Вы уверены, что хотите выйти?</div>
            </Modal.Body>
            <Modal.Footer>
                <Button size='small' variant='secondary' onClick={props.onClose}>
                    Отмена
                </Button>
                <Button
                    isLoading={isLoggingOut}
                    size='small'
                    variant='error'
                    onClick={handleLogout}
                >
                    Выйти
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

LogoutModal.displayName = 'LogoutModal'
