import React, { lazy, Suspense, useEffect, useState } from 'react'
import { getUsersProfileWebim, postUsersAuthRefresh } from '@dostavkee/contracts-console/api'
import { AXIOS_INSTANCE } from '@dostavkee/contracts-console/axios-client'
import { getUsersProfileQueryKey } from '@dostavkee/contracts-console/hooks'
import { AXIOS_INSTANCE as AXIOS_INSTANCE_EXTERNAL } from '@dostavkee/contracts-external/axios-client'
import { AppVersion, Spinner } from '@dostavkee/web-ui'
import { useQueryClient } from '@tanstack/react-query'
import { createRootRoute, Outlet, ScrollRestoration } from '@tanstack/react-router'
import createAuthRefreshInterceptor from 'axios-auth-refresh'
import toast from 'react-hot-toast'

import {
    ORDER_FORM_PERSISTED_SENDER_STORAGE_KEY,
    ORDER_FORM_PERSISTED_STORAGE_KEY,
} from '@/shared/constants'
import { useAuth } from '@/shared/hooks'
import { NotFound } from '@/shared/ui'

const Toaster = lazy(() =>
    import('@/shared/ui/toaster/toaster').then((module) => ({ default: module.Toaster }))
)

const TanStackRouterDevtools = import.meta.env.PROD
    ? () => null
    : lazy(() =>
          import('@tanstack/router-devtools').then((module) => ({
              default: module.TanStackRouterDevtools,
          }))
      )

const TanStackReactQueryDevtools = import.meta.env.PROD
    ? () => null
    : lazy(() =>
          import('@tanstack/react-query-devtools/build/modern/production.js').then((module) => ({
              default: module.ReactQueryDevtools,
          }))
      )

export const Route = createRootRoute({
    component: Root,
    notFoundComponent: NotFound,
})

function Root() {
    const [isInitialized, setIsInitialized] = useState<boolean>(false)

    const { auth, setAuth, profile, setRegisterUser, isAuthenticated, isFullyRegistered } =
        useAuth()

    const [isInitWebimVisitor, setIsInitWebimVisitor] = useState(false)

    const queryClient = useQueryClient()

    /**
     * Если токен изменился, то подменяем его в interceptors axios
     */
    useEffect(() => {
        if (auth?.access_token) {
            AXIOS_INSTANCE.defaults.headers.Authorization = `Bearer ${auth?.access_token}`
        }
    }, [auth?.access_token])

    /**
     * Инициализация приложения
     */
    useEffect(() => {
        /**
         * Устанавливаем базовый URL для axios перед тем, как приложение будет инициализировано
         */
        AXIOS_INSTANCE.defaults.baseURL = __GLOBAL__.VITE_API_URL
        AXIOS_INSTANCE_EXTERNAL.defaults.baseURL = __GLOBAL__.VITE_EXTERNAL_API_URL

        /**
         * Создаем интерсептор для обновления токена
         */
        createAuthRefreshInterceptor(
            AXIOS_INSTANCE,
            async (failedRequest: any) => {
                const auth = localStorage.getItem('@dostavkee/auth')
                if (!auth || auth === 'undefined') {
                    return Promise.reject()
                }

                try {
                    const response = await postUsersAuthRefresh()

                    setAuth({
                        access_token: response.access_token,
                    })

                    AXIOS_INSTANCE.defaults.headers.Authorization = `Bearer ${response.access_token}`

                    failedRequest.response.config.headers['Authorization'] =
                        'Bearer ' + response.access_token

                    return Promise.resolve()
                } catch (error) {
                    console.error(error)

                    setAuth(undefined)

                    queryClient.clear()

                    AXIOS_INSTANCE.defaults.headers.Authorization = ''

                    setRegisterUser(undefined)

                    /**
                     * Clear persisted order form data
                     */
                    localStorage.removeItem(ORDER_FORM_PERSISTED_STORAGE_KEY)
                    localStorage.removeItem(ORDER_FORM_PERSISTED_SENDER_STORAGE_KEY)

                    toast.error('Ваша сессия истекла, пожалуйста, авторизуйтесь заново')

                    return Promise.reject()
                }
            },
            {
                pauseInstanceWhileRefreshing: false,
                interceptNetworkError: false,
                statusCodes: [401],
            }
        )

        /**
         * Ставим флаг инициализации приложения о том, что приложение готово к работе
         */
        setIsInitialized(true)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    /**
     * Получаем профиль пользователя после авторизации
     * Если пользователь авторизован и профиль не загружен, то загружаем его
     */
    useEffect(() => {
        if (isInitialized && auth?.access_token && !profile?.user?.id) {
            queryClient.invalidateQueries({
                queryKey: getUsersProfileQueryKey(),
            })
        }
    }, [auth?.access_token, isInitialized, profile, queryClient])

    useEffect(() => {
        console.info(`Version: ${APP_VERSION}`)
    }, [])

    /**
     * Заполняем webim_visitor после авторизации,
     * если пользователь полностью зарегистрирован
     */
    useEffect(() => {
        if (
            isAuthenticated &&
            isFullyRegistered &&
            profile?.user?.company &&
            !webim_visitor?.fields?.id &&
            !isInitWebimVisitor
        ) {
            setIsInitWebimVisitor(true)
            getUsersProfileWebim().then((response) => {
                webim_visitor = {
                    fields: {
                        display_name: response.fields.name,
                        email: response.fields.email,
                        id: response.fields.id,
                        info: response.fields.info,
                        phone: response.fields.phone,
                    },
                    hash: response.hash,
                }

                if (typeof webim?.api?.onProvidedVisitorChanged == 'function') {
                    webim.api.onProvidedVisitorChanged()
                }
                setIsInitWebimVisitor(false)
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isAuthenticated, isFullyRegistered, isInitWebimVisitor])

    /**
     * TODO: Refactor me please
     */
    if (!isInitialized || (auth?.access_token && !profile?.user?.id)) {
        return (
            <div
                style={{
                    display: 'flex',
                    height: '100dvh',
                    width: '100vw',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                <Spinner />
            </div>
        )
    }

    if (import.meta.env.PROD) {
        return (
            <>
                <ScrollRestoration />
                <Outlet />
                <Suspense>
                    <Toaster />
                </Suspense>
                {!location.hostname.includes('business.dostavkee') && (
                    <AppVersion version={APP_VERSION} />
                )}
            </>
        )
    }

    return (
        <Suspense fallback={<Spinner />}>
            <ScrollRestoration />
            <Outlet />
            <Suspense>
                <TanStackRouterDevtools
                    position='bottom-right'
                    toggleButtonProps={{ style: { right: 72, bottom: 18 } }}
                />
            </Suspense>
            <Suspense>
                <TanStackReactQueryDevtools buttonPosition='bottom-right' position='bottom' />
            </Suspense>
            <Suspense>
                <Toaster />
            </Suspense>
            <AppVersion version={APP_VERSION} />
        </Suspense>
    )
}
