import React, { FC } from 'react'

import styles from './tapbar.module.scss'

export interface TapbarProps {
    children?: React.ReactNode
}

export const Tapbar: FC<TapbarProps> = ({ children }) => {
    return <div className={styles['tapbar']}>{children}</div>
}

Tapbar.displayName = 'Tapbar'
