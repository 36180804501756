import type { ResponseConfig } from '../../axios-client'
import client from '../../axios-client'
import type { PostUsersAuthRefreshMutationResponse } from '../entities/PostUsersAuthRefresh'

/**
 * @description User refresh
 * @summary Refresh
 * @link /users/auth/refresh
 */
export async function postUsersAuthRefresh(
    options: Partial<Parameters<typeof client>[0]> = {}
): Promise<ResponseConfig<PostUsersAuthRefreshMutationResponse>['data']> {
    const res = await client<PostUsersAuthRefreshMutationResponse>({
        method: 'post',
        url: `/users/auth/refresh`,
        ...options,
    })

    return res.data
}
