import React, { FC } from 'react'

import { IconSvgProps } from './icon'

export const IconEdit: FC<IconSvgProps> = ({
    size = 24,
    color = 'var(--text-and-icon-primary)',
}) => {
    return (
        <svg
            fill='none'
            height={size}
            viewBox='0 0 24 24'
            width={size}
            xmlns='http://www.w3.org/2000/svg'
        >
            <path
                d='M16.7072 3.29289L20.7072 7.29289C21.0977 7.68342 21.0977 8.31658 20.7072 8.70711L9.70717 19.7071C9.56757 19.8467 9.38978 19.9419 9.19618 19.9806L4.19618 20.9806C3.49646 21.1205 2.87954 20.5036 3.01949 19.8039L4.01949 14.8039C4.05821 14.6103 4.15336 14.4325 4.29296 14.2929L15.293 3.29289C15.6835 2.90237 16.3167 2.90237 16.7072 3.29289ZM13.4995 7.914L5.92127 15.493L5.27482 18.7252L8.50706 18.0788L16.0855 10.5L13.4995 7.914ZM16.0001 5.41421L14.9145 6.5L17.4995 9.085L18.5859 8L16.0001 5.41421Z'
                fill={color}
            />
        </svg>
    )
}
