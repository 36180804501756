import {
    ServiceOrder,
    ServiceOrderStage,
    ServiceOrderStatus,
} from '@dostavkee/contracts-console/entities'
import dayjs from 'dayjs'

export const getOrderTime = (order: ServiceOrder) => {
    if (
        order.status === ServiceOrderStatus.OrderStatusProcessing &&
        order.stage === ServiceOrderStage.OrderStageCourierAccepted &&
        order.courier?.arrival_time
    ) {
        return dayjs(order.courier?.arrival_time).diff(dayjs(), 'minute') > 0
            ? `Курьер заберёт посылку через: ~${dayjs(order.courier?.arrival_time).diff(dayjs(), 'minute')} мин.`
            : 'Курьер забирает посылку'
    }

    if (
        order.status === ServiceOrderStatus.OrderStatusProcessing &&
        order.stage === ServiceOrderStage.OrderStageCourierPickedUp
    ) {
        return dayjs(order.courier?.delivery_time).diff(dayjs(), 'minute') > 0
            ? `Время завершения заказа: ~${dayjs(order.courier?.delivery_time).diff(dayjs(), 'minute')} мин.`
            : 'Курьер задерживается'
    }

    return
}
