import React from 'react'
import { Switcher } from '@dostavkee/web-ui'

import { useMute } from '@/shared/hooks'

import styles from './sidebar-toggle-sound.module.scss'

export const SidebarToggleSound = () => {
    const { isMuted, setIsMuted } = useMute()

    return (
        <div className={styles['sidebar-toggle-sound']}>
            <Switcher
                checked={!isMuted}
                id='mute-unmute'
                onChange={(event) => setIsMuted(!event.target.checked)}
            >
                Звуковые уведомления
            </Switcher>
        </div>
    )
}
