import React, { FC } from 'react'

import { IconSvgProps } from './icon'

export const IconPhone: FC<IconSvgProps> = ({
    size = 24,
    color = 'var(--text-and-icon-primary)',
}) => {
    return (
        <svg
            fill='none'
            height={size}
            viewBox='0 0 24 24'
            width={size}
            xmlns='http://www.w3.org/2000/svg'
        >
            <path
                d='M5.79949 4.00956C5.67072 3.9951 5.59342 4.03355 5.48719 4.14805C4.5751 5.06097 4.17579 5.56258 4.06707 6.65097C3.72811 9.40191 5.02671 11.9493 8.54485 15.4691C13.4085 20.2883 17.2623 19.9654 17.2945 19.9557C18.4482 19.8375 18.9507 19.4382 19.8369 18.5526C20.014 18.3754 20.0366 18.0735 19.8845 17.9189L17.0843 15.1663C16.9756 15.0495 16.8187 14.9941 16.8243 15.0392C16.7567 15.0392 16.6288 15.0576 16.5176 15.1785L15.0886 16.6091C14.7705 16.9262 14.2836 16.9938 13.8923 16.7782C10.6454 14.9861 9.08987 13.4314 7.23737 10.1265C7.01209 9.72474 7.08939 9.22168 7.42421 8.90525L7.74044 8.61527C8.14078 8.25378 8.97329 7.50105 8.9828 7.10174C8.98425 7.04779 8.94106 6.97546 8.85239 6.88679L6.09504 4.13028C5.99191 4.02631 5.86872 4.00956 5.79949 4.00956ZM16.9903 21.9982C15.4468 21.9982 11.5116 21.239 7.12638 16.8933C3.1122 12.8783 1.64848 9.84711 2.06969 6.42796C2.24434 4.67612 3.03015 3.76403 4.03814 2.75522C4.46556 2.29225 5.08374 2.01364 5.75133 2C6.44929 2.01137 7.05219 2.24636 7.51598 2.7085L10.2741 5.46585C10.8876 6.08011 11.0035 6.72268 10.9924 7.15341C10.9643 8.2604 10.1268 9.14914 9.3879 9.83263C10.7557 12.1303 11.9481 13.3196 14.2086 14.6463L15.0686 13.7856C15.5016 13.3154 16.1514 13.0296 16.8243 13.0296H16.8293C17.4797 13.0313 18.0996 13.3018 18.5303 13.7712L21.2982 16.4907C21.3006 16.4932 21.3023 16.4946 21.3046 16.4971C22.2497 17.4431 22.2289 19.0033 21.2579 19.9743C20.2476 20.9831 19.3347 21.10247 17.5618 21.9459C17.4475 21.9756 17.2534 21.9982 16.9903 21.9982Z'
                fill={color}
            />
        </svg>
    )
}
