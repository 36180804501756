import type { UseMutationOptions } from '@tanstack/react-query'
import { useMutation } from '@tanstack/react-query'

import client from '../../axios-client'
import type {
    PatchOrdersId400,
    PatchOrdersIdMutationRequest,
    PatchOrdersIdMutationResponse,
    PatchOrdersIdPathParams,
} from '../entities/PatchOrdersId'

type PatchOrdersIdClient = typeof client<
    PatchOrdersIdMutationResponse,
    PatchOrdersId400,
    PatchOrdersIdMutationRequest
>
type PatchOrdersId = {
    data: PatchOrdersIdMutationResponse
    error: PatchOrdersId400
    request: PatchOrdersIdMutationRequest
    pathParams: PatchOrdersIdPathParams
    queryParams: never
    headerParams: never
    response: PatchOrdersIdMutationResponse
    client: {
        parameters: Partial<Parameters<PatchOrdersIdClient>[0]>
        return: Awaited<ReturnType<PatchOrdersIdClient>>
    }
}
/**
 * @description Обновление цены заказа
 * @summary Обновление цены заказа
 * @link /orders/:id
 */
export function usePatchOrdersId(
    options: {
        mutation?: UseMutationOptions<
            PatchOrdersId['response'],
            PatchOrdersId['error'],
            {
                id: PatchOrdersIdPathParams['id']
                data: PatchOrdersId['request']
            }
        >
        client?: PatchOrdersId['client']['parameters']
    } = {}
) {
    const { mutation: mutationOptions, client: clientOptions = {} } = options ?? {}

    return useMutation({
        mutationFn: async ({ id, data }) => {
            const res = await client<
                PatchOrdersId['data'],
                PatchOrdersId['error'],
                PatchOrdersId['request']
            >({
                method: 'patch',
                url: `/orders/${id}`,
                data,
                ...clientOptions,
            })

            return res.data
        },
        ...mutationOptions,
    })
}
