import React, { FC } from 'react'

import styles from './modal.module.scss'

interface ModalFooterProps {
    children: React.ReactNode
}

export const ModalFooter: FC<ModalFooterProps> = ({ children }) => {
    return <footer className={styles['modal__footer']}>{children}</footer>
}
