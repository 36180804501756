import { GetOrdersQueryParamsType, ServiceOrder } from '@dostavkee/contracts-console/entities'
import {
    getOrdersPublicIdQueryKey,
    getOrdersQueryKey,
    usePatchOrdersOrderIdArchive,
} from '@dostavkee/contracts-console/hooks'
import * as Sentry from '@sentry/react'
import { useQueryClient } from '@tanstack/react-query'
import toast from 'react-hot-toast'

interface UseOrderArchiveProps {
    order: ServiceOrder
}

export const useOrderArchive = ({ order }: UseOrderArchiveProps) => {
    const queryClient = useQueryClient()

    const { mutate, isPending } = usePatchOrdersOrderIdArchive({
        mutation: {
            onSuccess: async () => {
                /**
                 * Обновляем кэш заказа
                 */
                await queryClient.invalidateQueries({
                    queryKey: getOrdersPublicIdQueryKey(order.public_id.toString()),
                })

                /**
                 * Обновляем кэш списка активных заказов
                 */
                await queryClient.invalidateQueries({
                    queryKey: getOrdersQueryKey({ type: GetOrdersQueryParamsType.active }),
                })

                toast.success(`Заказ №${order.public_id} архивирован`)
            },
            onError: (error) => {
                Sentry.captureException(error)

                toast.error('Ошибка при архивации заказа')
            },
        },
    })

    return {
        mutate,
        isPending,
    }
}
