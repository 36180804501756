import React, { FC } from 'react'
import clsx from 'clsx'

import styles from './box.module.scss'

export interface BoxProps extends React.HTMLAttributes<HTMLDivElement> {
    children?: React.ReactNode
}

export const Box: FC<BoxProps> = ({ children, className, ...props }) => {
    return (
        <div className={clsx(styles['box'], className)} {...props}>
            {children}
        </div>
    )
}

Box.displayName = 'Box'
