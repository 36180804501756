import { createFileRoute } from '@tanstack/react-router'

export const Route = createFileRoute('/_auth/register/')({
    onEnter: () => {
        document.title = 'Dostavkee - Регистрация'
    },
    validateSearch: (search: Record<string, unknown>): { givemeweapon?: boolean } => {
        return { givemeweapon: search.givemeweapon as boolean }
    },
})
