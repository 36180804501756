import type { UseMutationOptions } from '@tanstack/react-query'
import { useMutation } from '@tanstack/react-query'

import client from '../../axios-client'
import type {
    PostUsersForgotPasswordVerify400,
    PostUsersForgotPasswordVerifyMutationRequest,
    PostUsersForgotPasswordVerifyMutationResponse,
} from '../entities/PostUsersForgotPasswordVerify'

type PostUsersForgotPasswordVerifyClient = typeof client<
    PostUsersForgotPasswordVerifyMutationResponse,
    PostUsersForgotPasswordVerify400,
    PostUsersForgotPasswordVerifyMutationRequest
>
type PostUsersForgotPasswordVerify = {
    data: PostUsersForgotPasswordVerifyMutationResponse
    error: PostUsersForgotPasswordVerify400
    request: PostUsersForgotPasswordVerifyMutationRequest
    pathParams: never
    queryParams: never
    headerParams: never
    response: PostUsersForgotPasswordVerifyMutationResponse
    client: {
        parameters: Partial<Parameters<PostUsersForgotPasswordVerifyClient>[0]>
        return: Awaited<ReturnType<PostUsersForgotPasswordVerifyClient>>
    }
}
/**
 * @description Подтверждение восстановления пароля
 * @summary Подтверждение
 * @link /users/forgot-password/verify
 */
export function usePostUsersForgotPasswordVerify(
    options: {
        mutation?: UseMutationOptions<
            PostUsersForgotPasswordVerify['response'],
            PostUsersForgotPasswordVerify['error'],
            {
                data: PostUsersForgotPasswordVerify['request']
            }
        >
        client?: PostUsersForgotPasswordVerify['client']['parameters']
    } = {}
) {
    const { mutation: mutationOptions, client: clientOptions = {} } = options ?? {}

    return useMutation({
        mutationFn: async ({ data }) => {
            const res = await client<
                PostUsersForgotPasswordVerify['data'],
                PostUsersForgotPasswordVerify['error'],
                PostUsersForgotPasswordVerify['request']
            >({
                method: 'post',
                url: `/users/forgot-password/verify`,
                data,
                ...clientOptions,
            })

            return res.data
        },
        ...mutationOptions,
    })
}
