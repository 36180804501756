import { createFileRoute } from '@tanstack/react-router'

export const Route = createFileRoute('/_dashboard/orders/create/')({
    validateSearch: (search: Record<string, unknown>): { public_id?: string } => {
        return { public_id: search.public_id as string }
    },
    onEnter: () => {
        document.title = 'Dostavkee - Создать заказ'
    },
})
