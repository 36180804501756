import React, { FC } from 'react'

import { IconSvgProps } from './icon'

export const IconClose: FC<IconSvgProps> = ({
    size = 24,
    color = 'var(--text-and-icon-primary)',
}) => {
    return (
        <svg
            fill='none'
            height={size}
            viewBox='0 0 24 24'
            width={size}
            xmlns='http://www.w3.org/2000/svg'
        >
            <path
                d='M6.04853 4.35147C5.5799 3.88284 4.8201 3.88284 4.35147 4.35147C3.88284 4.8201 3.88284 5.5799 4.35147 6.04853L10.3029 12L4.35147 17.9515C3.88284 18.4201 3.88284 19.1799 4.35147 19.6485C4.8201 20.1172 5.5799 20.1172 6.04853 19.6485L12 13.6971L17.9515 19.6485C18.4201 20.1172 19.1799 20.1172 19.6485 19.6485C20.1172 19.1799 20.1172 18.4201 19.6485 17.9515L13.6971 12L19.6485 6.04853C20.1172 5.5799 20.1172 4.8201 19.6485 4.35147C19.1799 3.88284 18.4201 3.88284 17.9515 4.35147L12 10.3029L6.04853 4.35147Z'
                fill={color}
            />
        </svg>
    )
}
