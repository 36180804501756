import React, { FC } from 'react'

import { IconSvgProps } from './icon'

export const IconWarningFilled: FC<IconSvgProps> = ({
    size = 24,
    color = 'var(--text-and-icon-primary)',
}) => {
    return (
        <svg
            fill='none'
            height={size}
            viewBox='0 0 24 24'
            width={size}
            xmlns='http://www.w3.org/2000/svg'
        >
            <path
                clipRule='evenodd'
                d='M21.7554 18.2936C22.4406 19.4894 21.6048 21 20.2579 21H3.74206C2.39524 21 1.55939 19.4894 2.24456 18.2936L10.5025 3.88126C11.1758 2.70624 12.8242 2.70625 13.4975 3.88126L21.7554 18.2936ZM11 10C11 9.44772 11.4477 9 12 9C12.5523 9 13 9.44771 13 10V13C13 13.5523 12.5523 14 12 14C11.4477 14 11 13.5523 11 13V10ZM10.75 16.5C10.75 17.1904 11.3096 17.75 12 17.75C12.6904 17.75 13.25 17.1904 13.25 16.5C13.25 15.8096 12.6904 15.25 12 15.25C11.3096 15.25 10.75 15.8096 10.75 16.5Z'
                fill={color}
                fillRule='evenodd'
            />
        </svg>
    )
}
