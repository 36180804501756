import type { UseMutationOptions } from '@tanstack/react-query'
import { useMutation } from '@tanstack/react-query'

import client from '../../axios-client'
import type {
    PostUsersProfileBalance400,
    PostUsersProfileBalanceMutationRequest,
    PostUsersProfileBalanceMutationResponse,
} from '../entities/PostUsersProfileBalance'

type PostUsersProfileBalanceClient = typeof client<
    PostUsersProfileBalanceMutationResponse,
    PostUsersProfileBalance400,
    PostUsersProfileBalanceMutationRequest
>
type PostUsersProfileBalance = {
    data: PostUsersProfileBalanceMutationResponse
    error: PostUsersProfileBalance400
    request: PostUsersProfileBalanceMutationRequest
    pathParams: never
    queryParams: never
    headerParams: never
    response: PostUsersProfileBalanceMutationResponse
    client: {
        parameters: Partial<Parameters<PostUsersProfileBalanceClient>[0]>
        return: Awaited<ReturnType<PostUsersProfileBalanceClient>>
    }
}
/**
 * @description Пополнение баланса
 * @summary Пополнение баланса
 * @link /users/profile/balance
 */
export function usePostUsersProfileBalance(
    options: {
        mutation?: UseMutationOptions<
            PostUsersProfileBalance['response'],
            PostUsersProfileBalance['error'],
            {
                data: PostUsersProfileBalance['request']
            }
        >
        client?: PostUsersProfileBalance['client']['parameters']
    } = {}
) {
    const { mutation: mutationOptions, client: clientOptions = {} } = options ?? {}

    return useMutation({
        mutationFn: async ({ data }) => {
            const res = await client<
                PostUsersProfileBalance['data'],
                PostUsersProfileBalance['error'],
                PostUsersProfileBalance['request']
            >({
                method: 'post',
                url: `/users/profile/balance`,
                data,
                ...clientOptions,
            })

            return res.data
        },
        ...mutationOptions,
    })
}
