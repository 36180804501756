export function addressFormatter(
    address: string,
    entry?: string,
    floor?: string,
    room?: string,
    intercom?: string,
    phone?: string
) {
    let formattedAddress = address

    if (entry && entry.length > 0) formattedAddress += `, п. ${entry}`
    if (floor && floor.length > 0) formattedAddress += `, э. ${floor}`
    if (room && room.length > 0) formattedAddress += `, кв./офис ${room}`
    if (intercom && intercom.length > 0) formattedAddress += `, домофон ${intercom}`
    if (phone && phone.length > 0) formattedAddress += `, тел. ${phone}`

    return formattedAddress
}
