import type { QueryKey, QueryObserverOptions, UseQueryResult } from '@tanstack/react-query'
import { queryOptions, useQuery } from '@tanstack/react-query'

import client from '../../axios-client'
import type {
    GetOrders400,
    GetOrdersQueryParams,
    GetOrdersQueryResponse,
} from '../entities/GetOrders'

type GetOrdersClient = typeof client<GetOrdersQueryResponse, GetOrders400, never>
type GetOrders = {
    data: GetOrdersQueryResponse
    error: GetOrders400
    request: never
    pathParams: never
    queryParams: GetOrdersQueryParams
    headerParams: never
    response: GetOrdersQueryResponse
    client: {
        parameters: Partial<Parameters<GetOrdersClient>[0]>
        return: Awaited<ReturnType<GetOrdersClient>>
    }
}
export const getOrdersQueryKey = (params: GetOrders['queryParams']) =>
    [{ url: '/orders' }, ...(params ? [params] : [])] as const
export type GetOrdersQueryKey = ReturnType<typeof getOrdersQueryKey>
export function getOrdersQueryOptions(
    params: GetOrders['queryParams'],
    options: GetOrders['client']['parameters'] = {}
) {
    const queryKey = getOrdersQueryKey(params)

    return queryOptions({
        queryKey,
        queryFn: async () => {
            const res = await client<GetOrders['data'], GetOrders['error']>({
                method: 'get',
                url: `/orders`,
                params,
                ...options,
            })

            return res.data
        },
    })
}
/**
 * @description Получение списка заказов
 * @summary Получение списока заказов
 * @link /orders
 */
export function useGetOrders<
    TData = GetOrders['response'],
    TQueryData = GetOrders['response'],
    TQueryKey extends QueryKey = GetOrdersQueryKey,
>(
    params: GetOrders['queryParams'],
    options: {
        query?: Partial<
            QueryObserverOptions<
                GetOrders['response'],
                GetOrders['error'],
                TData,
                TQueryData,
                TQueryKey
            >
        >
        client?: GetOrders['client']['parameters']
    } = {}
): UseQueryResult<TData, GetOrders['error']> & {
    queryKey: TQueryKey
} {
    const { query: queryOptions, client: clientOptions = {} } = options ?? {}
    const queryKey = queryOptions?.queryKey ?? getOrdersQueryKey(params)
    const query = useQuery({
        ...(getOrdersQueryOptions(params, clientOptions) as unknown as QueryObserverOptions),
        queryKey,
        ...(queryOptions as unknown as Omit<QueryObserverOptions, 'queryKey'>),
    }) as UseQueryResult<TData, GetOrders['error']> & {
        queryKey: TQueryKey
    }
    query.queryKey = queryKey as TQueryKey

    return query
}
