import React, { FC } from 'react'
import { ServiceOrder } from '@dostavkee/contracts-console/entities'
import { Button, Modal } from '@dostavkee/web-ui'

import { useOrderCancelReasons } from '../../api/queries/use-order-cancel-reasons'
import { OrderCancelFormValues } from './order-cancel-modal'
import { OrderCancelModalLoader } from './order-cancel-modal.loader'

interface OrderCancelModalContentSearchingProps {
    order: ServiceOrder
    onClose: () => void
    isPending: boolean
    onSubmit: (values: OrderCancelFormValues) => void
}

export const OrderCancelModalContentSearching: FC<OrderCancelModalContentSearchingProps> = ({
    order,
    onClose,
    isPending,
    onSubmit,
}) => {
    const { reasons, isLoading } = useOrderCancelReasons({ order })

    if (isLoading) {
        return <OrderCancelModalLoader />
    }

    return (
        <>
            <Modal.Header onClose={onClose}>Отменить заказ</Modal.Header>
            <Modal.Body>Отменённый заказ сохранится во вкладке «Архив»</Modal.Body>
            <Modal.Footer>
                <Button size='small' variant='secondary' onClick={onClose}>
                    Нет
                </Button>
                <Button
                    isLoading={isPending}
                    size='small'
                    variant='error'
                    onClick={() => onSubmit({ reason: reasons?.[0] })}
                >
                    Да, отменить
                </Button>
            </Modal.Footer>
        </>
    )
}
