import React, { FC, forwardRef, ReactNode, useEffect, useRef } from 'react'
import clsx from 'clsx'

import { InputMessage } from '../input-message'
import styles from './switcher.module.scss'

export interface SwitcherProps extends React.InputHTMLAttributes<HTMLInputElement> {
    isInvalid?: boolean
    errorMessage?: string
    hint?: string | ReactNode
}

export const Switcher: FC<SwitcherProps> = forwardRef<HTMLInputElement, SwitcherProps>(
    ({ isInvalid, errorMessage, hint, children, ...props }, ref) => {
        const inputRef = useRef<HTMLInputElement>(null)

        useEffect(() => {
            if (!ref) {
                return
            }

            if (typeof ref === 'function') {
                ref(inputRef.current)
            } else {
                ref.current = inputRef.current
            }
        }, [ref])

        return (
            <div
                className={clsx(styles['switcher'], {
                    [styles['switcher--invalid']]: isInvalid,
                    [styles['switcher--disabled']]: props.disabled,
                })}
            >
                <label className={styles['switcher__label']} htmlFor={props.id}>
                    <input
                        ref={inputRef}
                        aria-disabled={props.disabled}
                        aria-invalid={isInvalid}
                        className={styles['switcher__input']}
                        type='checkbox'
                        {...props}
                    />

                    <div className={styles['switcher__icon']} />
                    {children && <span className={styles['switcher__text']}>{children}</span>}
                </label>

                {errorMessage ? (
                    <InputMessage variant='error'>{errorMessage}</InputMessage>
                ) : typeof hint === 'string' ? (
                    <InputMessage variant='info'>{hint}</InputMessage>
                ) : (
                    hint
                )}
            </div>
        )
    }
)

Switcher.displayName = 'Switcher'
