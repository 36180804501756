import React, { FC, memo, useEffect, useMemo, useRef } from 'react'
import { GetOrdersQueryParamsType } from '@dostavkee/contracts-console/entities'
import { usePrevious } from '@dostavkee/react-hooks'

import { STAGES_ALLOWED_SHOW_ORDER_ALERT } from '@/shared/constants'

import { useOrdersFetch } from '../../api/queries/use-orders-fetch'
import { OrderAlert } from '../order-alert'
import styles from './order-alerts.module.scss'

interface OrderAlertProps {}

export const OrderAlerts: FC<OrderAlertProps> = memo(() => {
    const ref = useRef<HTMLDivElement>(null)
    const { orders } = useOrdersFetch({
        params: {
            type: GetOrdersQueryParamsType.active,
        },
    })

    const ordersAlerts = useMemo(
        () => orders?.filter((order) => STAGES_ALLOWED_SHOW_ORDER_ALERT.has(order.stage)) || [],
        [orders]
    )

    const previousOrderAlertsLength = usePrevious(ordersAlerts?.length)

    useEffect(() => {
        if (previousOrderAlertsLength !== ordersAlerts?.length) {
            ref.current?.scrollTo({
                left: 0,
                behavior: 'smooth',
            })
        }
    }, [ordersAlerts?.length, previousOrderAlertsLength])

    if (!ordersAlerts?.length) {
        return null
    }

    return (
        <div ref={ref} className={styles['order-alerts']}>
            {ordersAlerts?.map((order) => <OrderAlert key={order.id} order={order} />)}
        </div>
    )
})

OrderAlerts.displayName = 'OrderAlerts'
