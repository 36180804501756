import React, { FC } from 'react'

import { IconSvgProps } from './icon'

export const IconArrowRight: FC<IconSvgProps> = ({
    size = 24,
    color = 'var(--text-and-icon-primary)',
}) => {
    return (
        <svg
            fill='none'
            height={size}
            viewBox='0 0 24 24'
            width={size}
            xmlns='http://www.w3.org/2000/svg'
        >
            <path
                d='M17.5861 12.9998L12.293 18.2929C11.9025 18.6834 11.9025 19.3164 12.293 19.7069C12.6835 20.0974 13.3165 20.0974 13.707 19.7069L20.707 12.7069C20.8935 12.5209 21 12.2634 21 11.9999C21 11.7364 20.8935 11.4789 20.707 11.2929L13.707 4.29286C13.3165 3.90236 12.6835 3.90236 12.293 4.29286C11.9025 4.68336 11.9025 5.31637 12.293 5.70687L17.5859 10.9998L3.9 10.9998C3.40275 10.9998 3 11.4473 3 11.9998C3 12.5523 3.40275 12.9998 3.9 12.9998L17.5861 12.9998Z'
                fill={color}
            />
        </svg>
    )
}
