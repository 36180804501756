import React, { FC } from 'react'

import { IconSvgProps } from './icon'

export const IconCourier: FC<IconSvgProps> = ({
    size = 24,
    color = 'var(--text-and-icon-primary)',
}) => {
    return (
        <svg
            fill='none'
            height={size}
            viewBox='0 0 24 24'
            width={size}
            xmlns='http://www.w3.org/2000/svg'
        >
            <path
                clipRule='evenodd'
                d='M6 11V3C6 2.44772 6.44772 2 7 2H20C20.5523 2 21 2.44772 21 3V15.1554C21.5902 15.4263 22 16.0224 22 16.7143C22 19.0812 20.0812 21 17.7143 21H8C8 21.5523 7.55228 22 7 22H3C2.44772 22 2 21.5523 2 21V12C2 11.4477 2.44772 11 3 11H6ZM12 4V6H15V4H12ZM10 4V7C10 7.55228 10.4477 8 11 8H16C16.5523 8 17 7.55228 17 7V4H19V15H14.95C14.9828 14.8384 15 14.6712 15 14.5C15 13.1193 13.8807 12 12.5 12H9.07692C8.69806 12 8.33518 12.0685 8 12.1937V4H10ZM6 13H4V20H6V13ZM8 19H17.7143C18.8799 19 19.8417 18.1275 19.9823 17H11V15H12.5C12.7761 15 13 14.7761 13 14.5C13 14.2239 12.7761 14 12.5 14H9.07692C8.50802 14 8.04215 14.4411 8.00271 15H8V19Z'
                fill={color}
                fillRule='evenodd'
            />
            <path
                d='M10 16C10 15.4477 10.4477 15 11 15C11.5523 15 12 15.4477 12 16C12 16.5523 11.5523 17 11 17C10.4477 17 10 16.5523 10 16Z'
                fill={color}
            />
        </svg>
    )
}
