import React, { FC, useCallback, useState } from 'react'
import {
    GetOrdersQueryParamsType,
    ServiceOrder,
    ServiceOrderStage,
} from '@dostavkee/contracts-console/entities'
import { getOrdersQueryKey } from '@dostavkee/contracts-console/hooks'
import { moneyFormatter } from '@dostavkee/helpers'
import { Button, Modal } from '@dostavkee/web-ui'
import { useQueryClient } from '@tanstack/react-query'
import toast from 'react-hot-toast'

import { useBalanceFetch } from '@/routes/_dashboard/-hooks/use-balance-fetch'
import { ORDER_UPDATE_AMOUNT_STEP } from '@/shared/constants'

import { useOrderUpdatePrice } from '../../api/mutations/use-order-update-price'
import { useOrderFetch } from '../../api/queries/use-order-fetch'
import styles from './order-update-price-modal.module.scss'

export interface OrderUpdatePriceModalProps {
    order: ServiceOrder
    onClose: () => void
}

export const OrderUpdatePriceModal: FC<OrderUpdatePriceModalProps> = ({ onClose, order }) => {
    const queryClient = useQueryClient()

    const { refetch: refetchOrder } = useOrderFetch({
        params: {
            public_id: order.public_id.toString(),
        },
    })

    const { balance } = useBalanceFetch()

    const [increaseValue, setIncreaseValue] = useState(0)

    const newAmount = increaseValue + order.amount

    const { mutate, isPending } = useOrderUpdatePrice({ order })

    const onSubmit = useCallback(async () => {
        const response = await refetchOrder()

        /**
         * Получаем актуальные данные заказа
         */
        const relevantOrder = response?.data?.order

        if (!relevantOrder) {
            toast.error('Не удалось получить информацию о заказе')

            return
        }

        if (relevantOrder.stage !== ServiceOrderStage.OrderStageSearching) {
            /**
             * Обновляем кэш списка активных заказов
             */
            await queryClient.invalidateQueries({
                queryKey: getOrdersQueryKey({ type: GetOrdersQueryParamsType.active }),
            })

            toast.error('Невозможно поднять цену заказа. Статус заказа был изменен')

            onClose()

            return
        }

        if (!balance?.amount) {
            toast.error('Не удалось получить информацию о балансе')

            return
        }

        /**
         * Проверяем достаточно ли средств на балансе для поднятия цены заказа
         */
        if (balance.amount < increaseValue) {
            toast.error(
                'Недостаточно средств на балансе. Пожалуйста пополните баланс и попробуйте снова.'
            )

            onClose()

            return
        }

        mutate(
            {
                id: order.id,
                data: {
                    amount: newAmount,
                },
            },
            {
                onSuccess: () => onClose(),
            }
        )
    }, [
        balance?.amount,
        increaseValue,
        mutate,
        onClose,
        order.id,
        queryClient,
        refetchOrder,
        newAmount,
    ])

    return (
        <Modal isOpen onClose={onClose}>
            <Modal.Header onClose={onClose}>
                <div>Ускорить поиск</div>
            </Modal.Header>
            <Modal.Body>
                <div className={styles['order-update-price']}>
                    <Button
                        isRounded
                        disabled={order.amount >= newAmount}
                        size='small'
                        onClick={() =>
                            setIncreaseValue((value) => value - ORDER_UPDATE_AMOUNT_STEP)
                        }
                    >
                        -{ORDER_UPDATE_AMOUNT_STEP}
                    </Button>
                    <strong>{moneyFormatter(newAmount)}</strong>
                    <Button
                        isRounded
                        size='small'
                        onClick={() =>
                            setIncreaseValue((value) => value + ORDER_UPDATE_AMOUNT_STEP)
                        }
                    >
                        +{ORDER_UPDATE_AMOUNT_STEP}
                    </Button>
                </div>
            </Modal.Body>

            <Modal.Footer>
                <Button size='small' variant='secondary' onClick={onClose}>
                    Отмена
                </Button>
                <Button
                    disabled={order.amount >= newAmount}
                    isLoading={isPending}
                    size='small'
                    onClick={onSubmit}
                >
                    Поднять цену
                </Button>
            </Modal.Footer>
        </Modal>
    )
}
