import React, { FC } from 'react'

import { IconSvgProps } from './icon'

export const IconExit: FC<IconSvgProps> = ({
    size = 24,
    color = 'var(--text-and-icon-primary)',
}) => {
    return (
        <svg
            fill='none'
            height={size}
            viewBox='0 0 24 24'
            width={size}
            xmlns='http://www.w3.org/2000/svg'
        >
            <path
                d='M6 5H15C15.5523 5 16 4.55228 16 4C16 3.44772 15.5523 3 15 3H6C4.345 3.0005 3 4.343 3 6V18C3 19.657 4.345 20.9995 6 21H14.9999C15.5522 21 16 20.5522 15.9999 19.9999C15.9999 19.4477 15.5522 19 14.9999 19H6C5.72 18.9995 5.47504 18.8895 5.29504 18.707C5.11004 18.523 5 18.2795 5 18V6C5 5.7205 5.11004 5.477 5.29504 5.293C5.47504 5.1105 5.72 5.0005 6 5Z'
                fill={color}
            />
            <path
                d='M17.7047 15.7068L20.671 12.7406C20.8727 12.5577 20.9999 12.2937 20.9999 12C20.9999 11.7076 20.8739 11.4446 20.6737 11.2618L17.7047 8.29287C17.3147 7.90238 16.6848 7.90238 16.2948 8.29287C15.8998 8.68337 15.8998 9.31637 16.2948 9.70686L17.5862 11H9.99988C9.44988 11 8.99988 11.4475 8.99988 12C8.99988 12.5525 9.44988 12.9999 9.99988 12.9999H17.586L16.2948 14.2928C15.8998 14.6833 15.8998 15.3163 16.2948 15.7068C16.6848 16.0973 17.3147 16.0973 17.7047 15.7068Z'
                fill={color}
            />
        </svg>
    )
}
