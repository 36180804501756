import type { QueryKey, QueryObserverOptions, UseQueryResult } from '@tanstack/react-query'
import { queryOptions, useQuery } from '@tanstack/react-query'

import client from '../../axios-client'
import type {
    GetUsersProfileBalance400,
    GetUsersProfileBalanceQueryResponse,
} from '../entities/GetUsersProfileBalance'

type GetUsersProfileBalanceClient = typeof client<
    GetUsersProfileBalanceQueryResponse,
    GetUsersProfileBalance400,
    never
>
type GetUsersProfileBalance = {
    data: GetUsersProfileBalanceQueryResponse
    error: GetUsersProfileBalance400
    request: never
    pathParams: never
    queryParams: never
    headerParams: never
    response: GetUsersProfileBalanceQueryResponse
    client: {
        parameters: Partial<Parameters<GetUsersProfileBalanceClient>[0]>
        return: Awaited<ReturnType<GetUsersProfileBalanceClient>>
    }
}
export const getUsersProfileBalanceQueryKey = () => [{ url: '/users/profile/balance' }] as const
export type GetUsersProfileBalanceQueryKey = ReturnType<typeof getUsersProfileBalanceQueryKey>
export function getUsersProfileBalanceQueryOptions(
    options: GetUsersProfileBalance['client']['parameters'] = {}
) {
    const queryKey = getUsersProfileBalanceQueryKey()

    return queryOptions({
        queryKey,
        queryFn: async () => {
            const res = await client<
                GetUsersProfileBalance['data'],
                GetUsersProfileBalance['error']
            >({
                method: 'get',
                url: `/users/profile/balance`,
                ...options,
            })

            return res.data
        },
    })
}
/**
 * @description Получение баланса пользователя
 * @summary Баланс пользователя
 * @link /users/profile/balance
 */
export function useGetUsersProfileBalance<
    TData = GetUsersProfileBalance['response'],
    TQueryData = GetUsersProfileBalance['response'],
    TQueryKey extends QueryKey = GetUsersProfileBalanceQueryKey,
>(
    options: {
        query?: Partial<
            QueryObserverOptions<
                GetUsersProfileBalance['response'],
                GetUsersProfileBalance['error'],
                TData,
                TQueryData,
                TQueryKey
            >
        >
        client?: GetUsersProfileBalance['client']['parameters']
    } = {}
): UseQueryResult<TData, GetUsersProfileBalance['error']> & {
    queryKey: TQueryKey
} {
    const { query: queryOptions, client: clientOptions = {} } = options ?? {}
    const queryKey = queryOptions?.queryKey ?? getUsersProfileBalanceQueryKey()
    const query = useQuery({
        ...(getUsersProfileBalanceQueryOptions(clientOptions) as unknown as QueryObserverOptions),
        queryKey,
        ...(queryOptions as unknown as Omit<QueryObserverOptions, 'queryKey'>),
    }) as UseQueryResult<TData, GetUsersProfileBalance['error']> & {
        queryKey: TQueryKey
    }
    query.queryKey = queryKey as TQueryKey

    return query
}
