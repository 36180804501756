import type { UseMutationOptions } from '@tanstack/react-query'
import { useMutation } from '@tanstack/react-query'

import client from '../../axios-client'
import type {
    PostUsersForgotPassword400,
    PostUsersForgotPasswordMutationRequest,
    PostUsersForgotPasswordMutationResponse,
} from '../entities/PostUsersForgotPassword'

type PostUsersForgotPasswordClient = typeof client<
    PostUsersForgotPasswordMutationResponse,
    PostUsersForgotPassword400,
    PostUsersForgotPasswordMutationRequest
>
type PostUsersForgotPassword = {
    data: PostUsersForgotPasswordMutationResponse
    error: PostUsersForgotPassword400
    request: PostUsersForgotPasswordMutationRequest
    pathParams: never
    queryParams: never
    headerParams: never
    response: PostUsersForgotPasswordMutationResponse
    client: {
        parameters: Partial<Parameters<PostUsersForgotPasswordClient>[0]>
        return: Awaited<ReturnType<PostUsersForgotPasswordClient>>
    }
}
/**
 * @description Восстановление пароля
 * @summary Восстановление пароля
 * @link /users/forgot-password
 */
export function usePostUsersForgotPassword(
    options: {
        mutation?: UseMutationOptions<
            PostUsersForgotPassword['response'],
            PostUsersForgotPassword['error'],
            {
                data: PostUsersForgotPassword['request']
            }
        >
        client?: PostUsersForgotPassword['client']['parameters']
    } = {}
) {
    const { mutation: mutationOptions, client: clientOptions = {} } = options ?? {}

    return useMutation({
        mutationFn: async ({ data }) => {
            const res = await client<
                PostUsersForgotPassword['data'],
                PostUsersForgotPassword['error'],
                PostUsersForgotPassword['request']
            >({
                method: 'post',
                url: `/users/forgot-password`,
                data,
                ...clientOptions,
            })

            return res.data
        },
        ...mutationOptions,
    })
}
