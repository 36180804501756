import React, { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { ServiceOrder, ServiceOrderStage } from '@dostavkee/contracts-console/entities'
import { GetOrderPublicInfoResponse } from '@dostavkee/contracts-external/entities'
import { useInterval } from '@dostavkee/react-hooks'
import dayjs from 'dayjs'

export interface CourierTimerProps {
    order: ServiceOrder | GetOrderPublicInfoResponse
    fallback?: string
}

const convertSeconds = (seconds: number): string => {
    const minutes = Math.floor((seconds % 3600) / 60)
    const remainingSeconds = seconds % 60

    const minuteString = `${minutes} мин.`
    const secondString = `${remainingSeconds} сек.`

    if (minutes > 0) {
        return `${minuteString} ${secondString}`
    }

    return secondString
}

export const CourierTimer: FC<CourierTimerProps> = ({ order, fallback }) => {
    const time = useMemo(() => {
        if (order.stage === ServiceOrderStage.OrderStageCourierAccepted) {
            return order.courier?.arrival_time
        }

        if (order.stage === ServiceOrderStage.OrderStageCourierPickedUp) {
            return order.courier?.delivery_time
        }

        return ''
    }, [order.courier?.arrival_time, order.courier?.delivery_time, order.stage])

    const seconds = dayjs(time).diff(dayjs(), 'seconds')

    const [deltaSeconds, setDeltaSeconds] = useState(seconds > 0 ? seconds : 0)

    useEffect(() => {
        if (time) {
            const seconds = dayjs(time).diff(dayjs(), 'seconds')
            setDeltaSeconds(seconds > 0 ? seconds : 0)
        }
    }, [time])

    const handleCountdown = useCallback(() => {
        if (deltaSeconds === 0) {
            return
        }

        setDeltaSeconds(deltaSeconds - 1)
    }, [deltaSeconds])

    useInterval(handleCountdown, deltaSeconds === 0 ? null : 1000)

    if (
        order.stage === ServiceOrderStage.OrderStageCourierAccepted &&
        order.courier?.arrival_time
    ) {
        return (
            <span>
                {deltaSeconds > 0
                    ? `Курьер заберёт посылку через: ~${convertSeconds(deltaSeconds)}`
                    : 'Курьер забирает посылку'}
            </span>
        )
    }

    if (
        order.stage === ServiceOrderStage.OrderStageCourierPickedUp &&
        order.courier?.delivery_time
    ) {
        return (
            <span>
                {deltaSeconds > 0
                    ? `Время завершения заказа: ~${convertSeconds(deltaSeconds)}`
                    : 'Курьер задерживается'}
            </span>
        )
    }

    return fallback ? <span>{fallback}</span> : null
}

CourierTimer.displayName = 'CourierTimer'
