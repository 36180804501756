import React, { FC, memo, useState } from 'react'
import { Button, IconExit, Logo, Truncate } from '@dostavkee/web-ui'
import { useIsFetching, useIsMutating } from '@tanstack/react-query'
import { Link } from '@tanstack/react-router'
import TopBarProgress from 'react-topbar-progress-indicator'

import { useAuth } from '@/shared/hooks'

import { LogoutModal } from '../logout-modal'
import styles from './header.module.scss'

export interface HeaderProps {}

TopBarProgress.config({
    barColors: {
        '0': '#B1F248',
        '1.0': '#0051C3',
    },
    shadowBlur: 0,
    barThickness: 3,
})

const GlobalLoader = () => {
    const isMutating = useIsMutating()
    const isFetching = useIsFetching({
        predicate: (query) => query.state.dataUpdateCount === 0,
    })

    if (isMutating || isFetching) {
        return <TopBarProgress />
    }

    return null
}

export const Header: FC<HeaderProps> = memo(() => {
    const { profile } = useAuth()

    const [isOpenLogoutModal, setIsOpenLogoutModal] = useState(false)

    return (
        <>
            <header className={styles['header']}>
                <div className={styles['header__logo']}>
                    <Link to='/orders/active'>
                        <Logo width={150} />
                    </Link>
                    <GlobalLoader />
                </div>
                <div className={styles['header__controls']}>
                    <div>
                        <Truncate length={40}>{profile?.user?.company?.name}</Truncate>
                    </div>
                    <Button
                        prefixEl={<IconExit size={20} />}
                        size='xs'
                        variant='secondary'
                        onClick={() => setIsOpenLogoutModal(true)}
                    >
                        Выйти
                    </Button>
                </div>
            </header>
            <LogoutModal isOpen={isOpenLogoutModal} onClose={() => setIsOpenLogoutModal(false)} />
        </>
    )
})

Header.displayName = 'Header'
