import React from 'react'
import { GetOrdersQueryParamsType } from '@dostavkee/contracts-console/entities'
import { createFileRoute, Outlet, useNavigate, useRouterState } from '@tanstack/react-router'
import clsx from 'clsx'
import { useSwipeable } from 'react-swipeable'

import { OrderAlerts, useOrdersFetch } from '@/entities/orders'
import { Title } from '@/shared/ui'

import styles from './_tabs.module.scss'

export const Route = createFileRoute('/_dashboard/orders/_tabs')({
    component: OrdersPage,
})

function OrdersPage() {
    const navigate = useNavigate()
    const pathname = useRouterState({ select: (state) => state.location.pathname })

    const { total } = useOrdersFetch({
        params: {
            type: GetOrdersQueryParamsType.active,
        },
        query: {
            refetchOnWindowFocus: false,
        },
    })

    const handlers = useSwipeable({
        onSwipedRight: () => {
            if (pathname !== '/orders/active') {
                navigate({ to: '/orders/active' })
            }
        },
        onSwipedLeft: () => {
            if (pathname !== '/orders/archive') {
                navigate({ to: '/orders/archive' })
            }
        },
        delta: 120,
        trackTouch: true,
        trackMouse: false,
    })

    return (
        <div className={styles['orders-page']}>
            <Title>Заказы</Title>

            <OrderAlerts />

            <div className={styles['tabs']}>
                <button
                    className={clsx(styles['tabs__tab-button'], {
                        [styles['tabs__tab-button--active']]: pathname === '/orders/active',
                    })}
                    onClick={() => navigate({ to: '/orders/active' })}
                >
                    <span>Активные</span>
                    {Boolean(total && total > 0) && (
                        <span className={styles['badge']}>{total}</span>
                    )}
                </button>
                <button
                    className={clsx(styles['tabs__tab-button'], {
                        [styles['tabs__tab-button--active']]: pathname === '/orders/archive',
                    })}
                    onClick={() => navigate({ to: '/orders/archive' })}
                >
                    <span>Архив</span>
                </button>
            </div>
            <div className={styles['orders-page__content']} {...handlers}>
                <Outlet />
            </div>
        </div>
    )
}
