import { useEffect, useState } from 'react'

import { useEventListener } from './use-event-listener'

export interface WindowSizeOptions {
    initialWidth?: number
    initialHeight?: number
}

export const useWindowSize = ({
    initialWidth = window?.innerWidth,
    initialHeight = window?.innerHeight,
}: WindowSizeOptions = {}) => {
    const [width, setWidth] = useState(initialWidth)
    const [height, setHeight] = useState(initialHeight)

    useEffect(() => {
        setWidth(window.innerWidth)
        setHeight(window.innerHeight)
    }, [])

    useEventListener(
        'resize',
        () => {
            setWidth(window.innerWidth)
            setHeight(window.innerHeight)
        },
        undefined,
        {
            passive: true,
        }
    )

    return { width, height }
}
