import React, { FC } from 'react'

import { IconClose } from '../icon'
import styles from './modal.module.scss'

interface ModalHeaderProps {
    children: React.ReactNode
    onClose?: () => void
}

export const ModalHeader: FC<ModalHeaderProps> = ({ children, onClose }) => {
    return (
        <header className={styles['modal__header']}>
            {children}
            {typeof onClose === 'function' && (
                <button className={styles['modal__close-button']} onClick={onClose}>
                    <IconClose color='var(--text-and-icon-secondary)' size={20} />
                </button>
            )}
        </header>
    )
}
