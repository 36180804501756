import React, { FC } from 'react'
import { ServiceOrder, ServiceOrderStage } from '@dostavkee/contracts-console/entities'

import { OrderAlertCanceledByTimeout } from './order-alert-canceled-by-timeout'
import { OrderAlertCourierArrived } from './order-alert-courier-arrived'
import { OrderAlertCourierCanceled } from './order-alert-courier-canceled'

interface OrderAlertProps {
    order: ServiceOrder
}

export const OrderAlert: FC<OrderAlertProps> = (props) => {
    if (props.order.stage === ServiceOrderStage.OrderStageCourierCanceled) {
        return <OrderAlertCourierCanceled {...props} />
    }

    if (props.order.stage === ServiceOrderStage.OrderStageCanceledByTimeout) {
        return <OrderAlertCanceledByTimeout {...props} />
    }

    if (props.order.stage === ServiceOrderStage.OrderStageCourierArrived) {
        return <OrderAlertCourierArrived {...props} />
    }

    return null
}
