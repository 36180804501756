import { OrderCancelFormValues } from '../order-cancel-modal'

export const validate = ({ reason, message }: OrderCancelFormValues) => {
    if (!reason?.id) {
        return 'Выберите причину отмены'
    }

    if (reason.message_required && typeof message !== 'string') {
        return 'Опишите жалобу'
    }

    if (reason.message_required && message!.length < 3) {
        return 'Минимальное количество символов жалобы - 3'
    }

    if (reason.message_required && message!.length > 255) {
        return 'Максимальное количество символов жалобы - 255'
    }
}
