const formatter = new Intl.NumberFormat('ru-RU', {
    style: 'currency',
    currency: 'RUB',
    maximumFractionDigits: 0,
})

export const moneyFormatter = (amount?: number) => {
    if (amount === undefined) {
        return ''
    }

    return formatter.format(amount)
}
