import React, { FC, memo, useState } from 'react'
import {
    ServiceCourierModel,
    ServiceOrder,
    ServiceOrderStage,
} from '@dostavkee/contracts-console/entities'
import { getCreatedTime, moneyFormatter } from '@dostavkee/helpers'
import {
    Button,
    Chip,
    Icon,
    IconArrowRight,
    IconEdit,
    IconMoreVertical,
    IconPhone,
    IconRepeat,
    Table,
} from '@dostavkee/web-ui'
import { Link, useNavigate } from '@tanstack/react-router'
import { Tooltip } from 'react-tooltip'

import { OrderUpdatePriceModal } from '@/entities/orders/ui/order-update-price-modal'
import { STAGE_TO_CHIP, STAGE_TO_TEXT, STAGES_ALLOWED_CANCEL_ORDER } from '@/shared/constants'
import { useAnalytics } from '@/shared/hooks'
import { CourierTimer } from '@/shared/ui'

import { OrderAddressesPoints } from '../order-addresses-points'
import { OrderCancelModal } from '../order-cancel-modal'
import { CourierPhoneModal } from './-ui'
import styles from './orders-table.module.scss'

export interface OrdersTableProps {
    orders: ServiceOrder[]
    isPlaceholderData?: boolean
}

type ModalState =
    | {
          visible: 'cancel'
          payload: ServiceOrder
      }
    | {
          visible: 'updatePrice'
          payload: ServiceOrder
      }
    | {
          visible: 'courierPhone'
          payload: ServiceCourierModel
      }

export const OrdersTable: FC<OrdersTableProps> = memo(
    ({ orders, isPlaceholderData }: OrdersTableProps) => {
        const analytics = useAnalytics()
        const navigate = useNavigate()
        const [modal, setModal] = useState<ModalState | undefined>()
        const [isOpenTooltip, setIsOpenTooltip] = useState(false)

        return (
            <>
                <Table isClickable style={{ position: 'relative' }}>
                    <thead>
                        <tr>
                            <th style={{ width: '8%' }}>№ заказа</th>
                            <th style={{ width: '14%' }}>Дата и время создания</th>
                            <th style={{ width: '30%' }}>Адреса</th>
                            <th style={{ width: '10%' }}>Стоимость</th>
                            <th style={{ width: '12%' }}>Статус</th>
                            <th style={{ width: '14%' }}>Время прибытия курьера</th>
                            <th style={{ width: '12%' }} />
                        </tr>
                    </thead>
                    <tbody>
                        {orders.map((order) => {
                            return (
                                <tr
                                    key={order.public_id}
                                    onClick={(event) => {
                                        event.preventDefault()
                                        event.stopPropagation()
                                        navigate({
                                            to: '/orders/$public_id',
                                            params: { public_id: order.public_id.toString() },
                                        })
                                    }}
                                >
                                    <td>
                                        <Link
                                            params={{ public_id: order.public_id.toString() }}
                                            to='/orders/$public_id'
                                        >
                                            №{order.public_id}
                                        </Link>
                                    </td>
                                    <td>
                                        <span>{getCreatedTime(order.created_date)}</span>
                                    </td>
                                    <td>
                                        <OrderAddressesPoints points={order.points} />
                                    </td>
                                    <td>
                                        <div className={styles.price}>
                                            <span>{moneyFormatter(order.amount)}</span>
                                            {order.stage ===
                                                ServiceOrderStage.OrderStageSearching && (
                                                <Button
                                                    size='2xs'
                                                    variant='ghost'
                                                    prefixEl={
                                                        <IconEdit
                                                            color='var(--text-and-icon-accent)'
                                                            size={22}
                                                        />
                                                    }
                                                    onClick={(event) => {
                                                        event.preventDefault()
                                                        event.stopPropagation()
                                                        setModal({
                                                            visible: 'updatePrice',
                                                            payload: order,
                                                        })
                                                    }}
                                                />
                                            )}
                                        </div>
                                    </td>
                                    <td>
                                        <span>
                                            <Chip variant={STAGE_TO_CHIP.get(order.stage)}>
                                                {STAGE_TO_TEXT.get(order.stage)}
                                            </Chip>
                                        </span>
                                    </td>
                                    <td>
                                        <CourierTimer fallback='–' order={order} />
                                    </td>
                                    <td>
                                        <div className={styles['controls']}>
                                            {order.courier && (
                                                <Button
                                                    prefixEl={<IconPhone />}
                                                    size='xs'
                                                    variant='ghost'
                                                    onClick={(event) => {
                                                        event.preventDefault()
                                                        event.stopPropagation()
                                                        analytics.track('b2b_active_order_call')
                                                        setModal({
                                                            visible: 'courierPhone',
                                                            payload: order.courier!,
                                                        })
                                                    }}
                                                />
                                            )}

                                            <Button
                                                data-tooltip-content={order.id}
                                                data-tooltip-id='cancel-order-button'
                                                prefixEl={<IconMoreVertical />}
                                                size='xs'
                                                variant='ghost'
                                                onClick={(event) => {
                                                    event.preventDefault()
                                                    event.stopPropagation()
                                                }}
                                            />
                                        </div>
                                    </td>
                                </tr>
                            )
                        })}
                    </tbody>
                    {isPlaceholderData && <tfoot className={styles.overlay} />}
                </Table>

                <Tooltip
                    clickable
                    openOnClick
                    className={styles['tooltip-more']}
                    id='cancel-order-button'
                    isOpen={isOpenTooltip}
                    opacity={1}
                    setIsOpen={setIsOpenTooltip}
                    render={({ content: id }) => {
                        const order = orders.find((order) => order.id === id)

                        if (!order) {
                            return null
                        }

                        return (
                            <>
                                <Button
                                    prefixEl={<IconArrowRight />}
                                    size='small'
                                    variant='ghost'
                                    onClick={() =>
                                        navigate({
                                            to: '/orders/$public_id',
                                            params: {
                                                public_id: order.public_id.toString(),
                                            },
                                        })
                                    }
                                >
                                    Перейти к заказу
                                </Button>
                                <Button
                                    prefixEl={<IconRepeat />}
                                    size='small'
                                    variant='ghost'
                                    onClick={(event) => {
                                        analytics.track('b2b_active_order_options_repeat')
                                        event.preventDefault()
                                        event.stopPropagation()
                                        setIsOpenTooltip(false)
                                        navigate({
                                            to: '/orders/create',
                                            search: { public_id: order.public_id.toString() },
                                        })
                                    }}
                                >
                                    Повторить
                                </Button>
                                {STAGES_ALLOWED_CANCEL_ORDER.has(order.stage) && (
                                    <Button
                                        color='error'
                                        size='small'
                                        variant='ghost'
                                        prefixEl={
                                            <Icon
                                                color='var(--extensions-background-error)'
                                                icon='close'
                                            />
                                        }
                                        onClick={() =>
                                            setModal({ visible: 'cancel', payload: order })
                                        }
                                    >
                                        Отменить
                                    </Button>
                                )}
                            </>
                        )
                    }}
                />
                {modal?.visible === 'courierPhone' && (
                    <CourierPhoneModal
                        courier={modal.payload}
                        onClose={() => setModal(undefined)}
                    />
                )}

                {modal?.visible === 'cancel' && (
                    <OrderCancelModal order={modal.payload} onClose={() => setModal(undefined)} />
                )}

                {modal?.visible === 'updatePrice' && (
                    <OrderUpdatePriceModal
                        order={modal.payload}
                        onClose={() => setModal(undefined)}
                    />
                )}
            </>
        )
    }
)

OrdersTable.displayName = 'OrdersTable'
