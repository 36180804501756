export enum ServiceOrderStage {
    'OrderStageCreated' = 'created',
    'OrderStageSearching' = 'searching',
    'OrderStageCourierAccepted' = 'courier_accepted',
    'OrderStageCourierArrived' = 'courier_arrived',
    'OrderStageCourierPickedUp' = 'courier_picked_up',
    'OrderStageOrderCompleted' = 'order_completed',
    'OrderStageCourierCanceled' = 'courier_canceled',
    'OrderStageClientCanceled' = 'client_canceled',
    'OrderStageCanceledByTimeout' = 'canceled_by_timeout',
    'OrderStageClientArchived' = 'client_archived',
    'OrderStageError' = 'stage_not_found',
}
