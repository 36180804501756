import React, { FC } from 'react'
import { truncateString } from '@dostavkee/helpers'

export interface TruncateProps {
    length: number
    children?: string
    ellipsis?: string
}

export const Truncate: FC<TruncateProps> = ({ length, ellipsis = '...', children }) => {
    if (!children) {
        return null
    }

    if (children.length <= length) {
        return children
    }

    const truncated = truncateString(children, length, ellipsis)

    return <span title={children}>{truncated}</span>
}

Truncate.displayName = 'Truncate'
