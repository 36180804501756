import React from 'react'
import ContentLoader from 'react-content-loader'

export const OrderCancelModalLoader = () => {
    return (
        <div style={{ padding: '16px 24px', boxSizing: 'border-box' }}>
            <ContentLoader height={190} width={'100%'}>
                <rect height='40' rx='8' ry='8' width='100%' x='0' y='10' />
                <rect height='60' rx='8' ry='8' width='100%' x='0' y='64' />
                <rect height='40' rx='8' ry='8' width='calc(100% - 34%)' x='34%' y='142' />
            </ContentLoader>
        </div>
    )
}
