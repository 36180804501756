import type { QueryKey, QueryObserverOptions, UseQueryResult } from '@tanstack/react-query'
import { queryOptions, useQuery } from '@tanstack/react-query'

import client from '../../axios-client'
import type {
    GetOrdersOrderIdCancelReasons400,
    GetOrdersOrderIdCancelReasonsPathParams,
    GetOrdersOrderIdCancelReasonsQueryResponse,
} from '../entities/GetOrdersOrderIdCancelReasons'

type GetOrdersOrderIdCancelReasonsClient = typeof client<
    GetOrdersOrderIdCancelReasonsQueryResponse,
    GetOrdersOrderIdCancelReasons400,
    never
>
type GetOrdersOrderIdCancelReasons = {
    data: GetOrdersOrderIdCancelReasonsQueryResponse
    error: GetOrdersOrderIdCancelReasons400
    request: never
    pathParams: GetOrdersOrderIdCancelReasonsPathParams
    queryParams: never
    headerParams: never
    response: GetOrdersOrderIdCancelReasonsQueryResponse
    client: {
        parameters: Partial<Parameters<GetOrdersOrderIdCancelReasonsClient>[0]>
        return: Awaited<ReturnType<GetOrdersOrderIdCancelReasonsClient>>
    }
}
export const getOrdersOrderIdCancelReasonsQueryKey = (
    orderId: GetOrdersOrderIdCancelReasonsPathParams['order_id']
) => [{ url: '/orders/:order_id/cancel-reasons', params: { orderId: orderId } }] as const
export type GetOrdersOrderIdCancelReasonsQueryKey = ReturnType<
    typeof getOrdersOrderIdCancelReasonsQueryKey
>
export function getOrdersOrderIdCancelReasonsQueryOptions(
    orderId: GetOrdersOrderIdCancelReasonsPathParams['order_id'],
    options: GetOrdersOrderIdCancelReasons['client']['parameters'] = {}
) {
    const queryKey = getOrdersOrderIdCancelReasonsQueryKey(orderId)

    return queryOptions({
        queryKey,
        queryFn: async () => {
            const res = await client<
                GetOrdersOrderIdCancelReasons['data'],
                GetOrdersOrderIdCancelReasons['error']
            >({
                method: 'get',
                url: `/orders/${orderId}/cancel-reasons`,
                ...options,
            })

            return res.data
        },
    })
}
/**
 * @description Получение причин отмены заказа
 * @summary Получение причин отмены заказа
 * @link /orders/:order_id/cancel-reasons
 */
export function useGetOrdersOrderIdCancelReasons<
    TData = GetOrdersOrderIdCancelReasons['response'],
    TQueryData = GetOrdersOrderIdCancelReasons['response'],
    TQueryKey extends QueryKey = GetOrdersOrderIdCancelReasonsQueryKey,
>(
    orderId: GetOrdersOrderIdCancelReasonsPathParams['order_id'],
    options: {
        query?: Partial<
            QueryObserverOptions<
                GetOrdersOrderIdCancelReasons['response'],
                GetOrdersOrderIdCancelReasons['error'],
                TData,
                TQueryData,
                TQueryKey
            >
        >
        client?: GetOrdersOrderIdCancelReasons['client']['parameters']
    } = {}
): UseQueryResult<TData, GetOrdersOrderIdCancelReasons['error']> & {
    queryKey: TQueryKey
} {
    const { query: queryOptions, client: clientOptions = {} } = options ?? {}
    const queryKey = queryOptions?.queryKey ?? getOrdersOrderIdCancelReasonsQueryKey(orderId)
    const query = useQuery({
        ...(getOrdersOrderIdCancelReasonsQueryOptions(
            orderId,
            clientOptions
        ) as unknown as QueryObserverOptions),
        queryKey,
        ...(queryOptions as unknown as Omit<QueryObserverOptions, 'queryKey'>),
    }) as UseQueryResult<TData, GetOrdersOrderIdCancelReasons['error']> & {
        queryKey: TQueryKey
    }
    query.queryKey = queryKey as TQueryKey

    return query
}
