import React, { FC } from 'react'

import styles from './modal.module.scss'

interface ModalBodyProps {
    children: React.ReactNode
}

export const ModalBody: FC<ModalBodyProps> = ({ children }) => {
    return <main className={styles['modal__body']}>{children}</main>
}
