import React, { FC, useCallback } from 'react'
import {
    CancelReasonResponse,
    ServiceOrder,
    ServiceOrderStage,
} from '@dostavkee/contracts-console/entities'
import { Modal } from '@dostavkee/web-ui'
import toast from 'react-hot-toast'

import { useOrderCancel } from '../../api/mutations/use-order-cancel'
import { validate } from './helpers/validate'
import { OrderCancelModalContentAccepted } from './order-cancel-modal-content-accepted'
import { OrderCancelModalContentPicked } from './order-cancel-modal-content-picked'
import { OrderCancelModalContentSearching } from './order-cancel-modal-content-searching'

export interface OrderCancelModalProps {
    order: ServiceOrder
    onClose: () => void
}

export interface OrderCancelFormValues {
    reason?: CancelReasonResponse
    message?: string
}

export const OrderCancelModal: FC<OrderCancelModalProps> = ({ order, onClose }) => {
    const { mutate, isPending } = useOrderCancel({ order })

    const onSubmit = useCallback(
        (values: OrderCancelFormValues) => {
            const error = validate(values)

            if (error) {
                toast.error(error)

                return
            }

            mutate(
                {
                    orderId: order.id,
                    data: {
                        reason_id: values.reason!.id,
                        reason_message: values.message,
                    },
                },
                {
                    onSuccess: () => onClose(),
                }
            )
        },
        [mutate, onClose, order.id]
    )

    return (
        <Modal isOpen size='small' onClose={onClose}>
            {order.stage === ServiceOrderStage.OrderStageSearching && (
                <OrderCancelModalContentSearching
                    isPending={isPending}
                    order={order}
                    onClose={onClose}
                    onSubmit={onSubmit}
                />
            )}
            {(order.stage === ServiceOrderStage.OrderStageCourierAccepted ||
                order.stage === ServiceOrderStage.OrderStageCourierArrived) && (
                <OrderCancelModalContentAccepted
                    isPending={isPending}
                    order={order}
                    onClose={onClose}
                    onSubmit={onSubmit}
                />
            )}
            {order.stage === ServiceOrderStage.OrderStageCourierPickedUp && (
                <OrderCancelModalContentPicked
                    isPending={isPending}
                    order={order}
                    onClose={onClose}
                    onSubmit={onSubmit}
                />
            )}
        </Modal>
    )
}

OrderCancelModal.displayName = 'OrderCancelModal'
