import type { ResponseConfig } from '../../axios-client'
import client from '../../axios-client'
import type { GetUsersProfileWebimQueryResponse } from '../entities/GetUsersProfileWebim'

/**
 * @description Получение авторизованного посетителя для Webim
 * @summary Get Webim Visitor
 * @link /users/profile/webim
 */
export async function getUsersProfileWebim(
    options: Partial<Parameters<typeof client>[0]> = {}
): Promise<ResponseConfig<GetUsersProfileWebimQueryResponse>['data']> {
    const res = await client<GetUsersProfileWebimQueryResponse>({
        method: 'get',
        url: `/users/profile/webim`,
        ...options,
    })

    return res.data
}
